import $ from 'jquery';
import React, {useState, useRef, Fragment} from 'react';
import {connect} from "react-redux";
import DataSource from "../../models/data_source";
import { BaseDropdownBtn } from "../../common/BaseHamburgerBtn";
import { BtnDropdownToggleSmallRound } from "../../common/dropdowns";
import UploadingItem from "../../tree_view/data_sources/UploadingItem";
import StyledDropzone from "./DropFiles";
import { generateDataSourceUploadState } from "../../helpers/uploads_callbacks";
import SourceDescription from "../../tree_view/data_sources/SourceDescription";
import SourceIcon from "../../tree_view/data_sources/SourceIcon";
import {
  enteredDSightLinksIframeRendererEffect,
  externalLinksIframeRendererEffect,
  onEnterPress
} from "../../tree_view/data_sources/helpers";
import {DescriptionRow, openLinkPanel} from "../../tree_view/data_sources/Item";
import { deleteReportSourceWarning } from "../../helpers/alert_helpers";
import UploadedImagesModal from "../../tree_view/modals/UploadedImagesModal";
import {setLinkPanelOpen} from "../../store/sidebar/actions";
import {isSafari} from "react-device-detect";
import {isOwnHostLink} from "../../helpers/link_helpers";
import {isReportDetails} from "../../helpers/wizard_helpers";
import { isBlank, isPresent } from "../../helpers/common";

const SourceItem = ({ source, users = [], hideMenu = false,
                      forceEditMode = false,
                      updateSource = () => {}, destroySource = () => {}, replaceSource = () => {},
                      dropFilesFormWrapper, sidebar, openLinkPanelSidebar, wizard
                    }) => {
  const sourceObj = new DataSource(source);
  const prevTitle = source.title || "";
  const prevLink = source.link_url || "";
  const [title, setTitle] = useState(source.title || "");
  const [editSource, setEditSource] = useState(forceEditMode && !sourceObj.isFile && !sourceObj.isLinkedForecastScenario);
  const [link, setLink] = useState(source.link_url || "");
  const [editSourceLink, setEditSourceLink] = useState(false);
  const [replacingFile, setReplacingFile] = useState(false);
  const [isReplaceFile, setIsReplaceFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const itemRef = useRef(null);
  const [canShowIframe, setCanShowIframe] = useState(false);
  const [showUploadedImagesModal, setShowUploadedImagesModal] = useState(false)
  const openUploadedImagesModal = () => {
    if (!sourceObj.isImage) return null

    setShowUploadedImagesModal(true)
  }

  externalLinksIframeRendererEffect(sourceObj, setCanShowIframe);
  enteredDSightLinksIframeRendererEffect(source, setCanShowIframe);

  const uploadOptions = generateDataSourceUploadState(source, cancelTokenSource, setCancelTokenSource, setUploadProgress, setReplacingFile)

  if(replacingFile) return <UploadingItem id={`${source.slug}-item`}
                                          source={source}
                                          uploadProgress={uploadProgress}
                                          {...uploadOptions}
  />

  const onRemoveSource = (slug, isScenario = false) => {
    deleteReportSourceWarning(confirmed => {
      if (!confirmed) return;

      destroySource(slug, {})
    }, isScenario)
  }

  const onReplaceFile = () => {
    $(itemRef.current).find('input[type=file]').click()
    setIsReplaceFile(true)
  }

  const sourceMenuEvents = {
    delete: 'delete',
    onReplaceFile: 'onReplaceFile',
    setEditSourceLink: 'setEditSourceLink',
    setEditSource: 'setEditSource',
    openLinkPanel: 'openLinkPanel'
  }

  const sourceMenuSelect = (eventKey) => {
    switch (eventKey) {
      case sourceMenuEvents.delete:
        return onRemoveSource(source.slug)
      case sourceMenuEvents.onReplaceFile:
        return onReplaceFile()
      case sourceMenuEvents.setEditSourceLink:
        return setEditSourceLink(true)
      case sourceMenuEvents.setEditSource:
        return setEditSource(true)
      case sourceMenuEvents.openLinkPanel:
        return openLinkPanel(sidebar, openLinkPanelSidebar, source)
    }
  }

  const hideOpenInPanelMenu = () => {
    const isReportDetailsOrNonLink = isReportDetails(wizard) || !sourceObj.isLink;
    const shouldHide = !canShowIframe || (isSafari && !isOwnHostLink(source.link_url));
    return isReportDetailsOrNonLink || shouldHide;
  };

  const sourceMenu = () => {
    return <BtnDropdownToggleSmallRound id={`source-menu-dropdown-${source.slug}`} onSelect={sourceMenuSelect}>
      <BaseDropdownBtn eventKey={sourceMenuEvents.openLinkPanel} title="Open in a panel"  hidden={hideOpenInPanelMenu()} />
      <BaseDropdownBtn eventKey={sourceMenuEvents.setEditSource} title="Edit display name" />
      <BaseDropdownBtn eventKey={sourceMenuEvents.setEditSourceLink} title="Edit link" hidden={!sourceObj.isLink} />
      <BaseDropdownBtn eventKey={sourceMenuEvents.onReplaceFile} title="Replace file" hidden={!sourceObj.isFile} />
      <BaseDropdownBtn eventKey={sourceMenuEvents.delete} title={`Remove ${sourceObj.isLink ? 'link' : 'file'}`} bsPrefix="text-danger" />
    </BtnDropdownToggleSmallRound>
  }

  const onSave = () => {
    if ((title === prevTitle) && isPresent(title)) return setEditSource(false);
    if (isBlank(title) && isBlank(source.link_url)) {
      setTitle(prevTitle)
      return setEditSource(false);
    }

    const attrs = dropFilesFormWrapper === 'report_data_source' ?
      { report_data_source: { title: title } } :
      { data_source: { title: title } }
    updateSource(source.slug, attrs);
    setEditSource(false)
  };

  const onSaveLink = () => {
    const attrs = dropFilesFormWrapper === 'report_data_source' ?
      { report_data_source: { link_url: link } } :
      { data_source: { link_url: link } }
    updateSource(source.slug, attrs);
    setEditSourceLink(false)
  };

  const onCancelTitle = () => {
    setEditSource(false);
    setTitle(prevTitle);
  };

  const onCancelLink = () => {
    setEditSourceLink(false);
    setLink(prevLink);
  };

  return <Fragment>
    <div className={`${editSourceLink || editSource ? 'edit-source-spacing' : 'mb-2'}`} ref={itemRef}>
      <div className="data-source-row lh-sm" >
        <div className="d-flex position-relative">
          <DescriptionRow source={source}
                          link={link}
                          setLink={setLink}
                          setTitle={setTitle}
                          title={title}
                          onSave={onSave}
                          onSaveLink={onSaveLink}
                          onEnterPress={(e) => onEnterPress(e, onSaveLink, onSave, onCancelTitle, onCancelLink)}
                          editSource={editSource}
                          editSourceLink={editSourceLink}
          />
          <a href={sourceObj.contentUrl()} title={sourceObj.contentUrl()} onClick={openUploadedImagesModal}
             target={sourceObj.contentTargetName} className={`d-flex px-0 w-100 ${hideMenu ? "" : "width-cut"}`}>
            <div className={`d-flex source-icon`}>
              <div className={`align-text-top fa-layers fa-3x ${sourceObj.iconBlock}`}>
                <SourceIcon sourceObj={sourceObj} />
              </div>
            </div>
            <div className="source-description ms-2 my-auto lh-sm" hidden={editSource || editSourceLink} style={{ maxWidth: 'calc(100vw - 200px)' }}>
              <SourceDescription {...{ sourceObj, users }} />
            </div>
            <div className="icon source-link ms-auto my-auto px-2">
              <i className={sourceObj.actionIconClass+' text-primary fa-lg'} />
            </div>
          </a>
          {sourceObj.isLinkedForecastScenario ?
            <div className={`btn btn-light btn-sm btn-sm-round text-danger my-auto`}
                 onClick={() => {onRemoveSource(source.slug, true)}}
                 hidden={hideMenu || !sourceObj.isLinkedForecastScenario}>
              <i className="fas fa-times w-100"/>
            </div> :
            <div className="d-flex source-actions">
              <div className="icon source-menu my-auto" hidden={hideMenu}>{sourceMenu()}</div>
            </div>
          }

          {sourceObj.isFile ?
            <StyledDropzone hiddenInput={true}
                            maxFiles={1}
                            onCreateSource={(data, config, callback, updateOnlySources) => {
                              replaceSource(source.slug, data, config, callback, updateOnlySources)
                            }}
                            dropFilesFormWrapper={dropFilesFormWrapper}
                            isReplaceFile={isReplaceFile}
                            {...uploadOptions} />
            : null}
        </div>
      </div>
    </div>
    <div className="modals">
      <UploadedImagesModal shown={showUploadedImagesModal} sourceObj={sourceObj} onClose={() => setShowUploadedImagesModal(false)} />
    </div>
  </Fragment>
};
const mapStateToProps = ({sidebar, wizard}) => ({sidebar, wizard});
const mapDispatchToProps = (dispatch) => ({
  openLinkPanelSidebar: (option, source) => {
    dispatch(setLinkPanelOpen(option, source));
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(SourceItem);
