import { visiblePeriodsScope } from "./period_helpers";
import { createComparisonRows, updateRowsOnOpen, enableHighlights, disableHighlights } from "./common";
import { ROW_DRIVER_ID_KEY } from "./ag_grid_vars";
import { isPresent } from "../../helpers/common";

let activeRequests = 0;
let updateTimeoutId;

const applyScrollBehavior = (gridReady, forecastBenchmarkScenario, expandedGroupIds) => {
  return gridReady && isPresent(forecastBenchmarkScenario) && expandedGroupIds.length > 0;
};

const combinedRows = (existingRows, newRows) => {
  return existingRows.map(rowData => {
    const newRow = newRows.find(row => row[ROW_DRIVER_ID_KEY] === rowData[ROW_DRIVER_ID_KEY]);
    return {...rowData, ...newRow};
  });
};

const updateOpenedGroups = (api, newRows) => {
  api.applyTransaction({ update: newRows });
};

const anyColumnsToUpdate = (group, periodsScope) => {
  if (!group) return false;

  return periodsScope.some(period => group.added_rows.some(row => !row.hasOwnProperty(period.name)));
};

export const onBodyScroll = () => clearTimeout(updateTimeoutId);

const updateRows = ({ rowNode, gridRef, group, forecastScenario, forecastBenchmarkScenario, newRows, editedCells, updateScenario, setScrollInProcess }) => {
  updateRowsOnOpen({
    api: gridRef.current.api,
    node: rowNode
  }, forecastScenario, group.output, forecastBenchmarkScenario, newRows, editedCells, true, updateScenario, true, (status) => {
    if(status) updateOpenedGroups(gridRef.current.api, newRows);
    activeRequests--;
    if (activeRequests === 0) {
      setScrollInProcess(false);
      enableHighlights(0);
    }
  });
};

const processRow = ({ config, rowNode, forecastScenario, forecastBenchmarkScenario, gridRef, expandedGroupIds, expandedGroups, periodsScope, setScrollInProcess, editedCells, updateScenario }) => {
  if (expandedGroupIds.includes(rowNode.id)) {
    const group = expandedGroups.find(g => g.id === rowNode.id);
    if (anyColumnsToUpdate(group, periodsScope)) {
      const createdRows = createComparisonRows(config, group.output, { node: rowNode }, forecastScenario, forecastBenchmarkScenario, periodsScope);
      const newRows = combinedRows(group.added_rows, createdRows);
      activeRequests++;
      if (activeRequests === 1) {
        setScrollInProcess(true);
        disableHighlights();
      }
      updateRows({ rowNode, gridRef, group, forecastScenario, forecastBenchmarkScenario, newRows, editedCells, updateScenario, setScrollInProcess })
    }
  }
};

export const onHorizontalScrollBody = ({ config, gridRef, forecastScenario, expandedGroupIds, expandedGroups, setScrollInProcess, forecastBenchmarkScenario, editedCells, updateScenario }) => {
  const firstVisibleRowIdx = gridRef.current.api.getFirstDisplayedRowIndex();
  const lastVisibleRowIdx = gridRef.current.api.getLastDisplayedRowIndex();
  const periodsScope = visiblePeriodsScope(gridRef.current, forecastScenario);
  for (let i = firstVisibleRowIdx; i <= lastVisibleRowIdx; i++) {
    const rowNode = gridRef.current.api.getDisplayedRowAtIndex(i);
    processRow({ config, rowNode, forecastScenario, forecastBenchmarkScenario, gridRef, expandedGroupIds, expandedGroups, periodsScope, setScrollInProcess, editedCells, updateScenario })
  }
};

const onHorizontalScroll = ({ gridReady, gridRef, config, forecastScenario, forecastBenchmarkScenario, editedCells, expandedGroupIds, expandedGroups, updateScenarioData, updateScenario, setScrollInProcess }) => {
  if (!applyScrollBehavior(gridReady, forecastBenchmarkScenario, expandedGroupIds)) return;

  // Clear any existing timeout to prevent multiple updates
  clearTimeout(updateTimeoutId);

  updateTimeoutId = setTimeout(() => {
    if(!gridRef.current) return;

    onHorizontalScrollBody({ config, gridRef, forecastScenario, expandedGroupIds, expandedGroups, setScrollInProcess, forecastBenchmarkScenario, editedCells, updateScenario });
  }, 200);
};

export const onBodyScrollEnd = ({ event, scrollInProcess, ...opts }) => {
  if (scrollInProcess) return;

  switch (event.direction) {
    case 'horizontal':
      return onHorizontalScroll({ event, ...opts });
  }
};
