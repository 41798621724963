import React, {useRef} from "react";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {
  openModal,
  transferDataAndCloseModal,
  updateRecommendation
} from "../../../../store/modals/actions";
import RecommendationEntryModal from "../../../../tree_view/modals/RecommendationEntryModal";
import {attachEventEffect} from "../../../../helpers/html_events_helpers";
import MultiEntryModal from "../../../../tree_view/modals/MultiEntryModal";
import Decision from "../../../../models/decision";

const showDetails = ({ modal, decision }) => modal.type === 'TreePanelRecommendationEntryModal' && modal.shown && modal.slug === decision.slug;

const DecisionModal = ({ modal, decision, decisionObj, onClose, updateRecommendation }) =>
  decisionObj.isRecordedOrRecommended || decisionObj.isTreeHistorical ?
    <RecommendationEntryModal key={`tree-panel-recommendation-entry-modal-${modal.slug}`}
                              show={showDetails({ modal, decision })}
                              onClose={onClose}
                              updateRecommendation={updateRecommendation} /> :
    <MultiEntryModal key={`tree-panel-multi-entry-modal-${modal.slug}`}
                     show={showDetails({ modal, decision })}
                     onClose={onClose}/>

const EditRecommendation = ({ modal, decision, tree, treeNode, openModal, transferDataAndCloseModal, updateRecommendation }) => {
  const ref = useRef();
  const decisionObj = new Decision(decision)

  const onEditDecision = (e) => {
    e.preventDefault();
    openModal({ decision, drivers: tree.drivers, data_sources: [], slug: treeNode.slug, type: 'TreePanelRecommendationEntryModal' })
  }
  attachEventEffect(ref, onEditDecision)

  return <>
    <Button className="btn btn-secondary btn-sm btn-sm-round" ref={ref}>
      <i className="fas fa-pencil-alt fa-lg w-100" />
    </Button>
    <DecisionModal decision={decision}
                   decisionObj={decisionObj}
                   onClose={transferDataAndCloseModal}
                   updateRecommendation={updateRecommendation}
                   modal={modal} />
  </>
}
const mapStateToProps = ({ modal, decision, tree }) => ({ modal, decision, tree });
export default connect(mapStateToProps, { openModal, transferDataAndCloseModal, updateRecommendation })(EditRecommendation);
