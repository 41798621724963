import React, { useState, useRef } from 'react';
import { CheckForm } from "../../../tree_wizard/helpers/DecisionFlowRow";

const ReadOnlyInput = ({ decision, onChangeSettings }) => {
  const [submit, setSubmit] = useState(true);
  const { read_only } = decision;
  const checkboxRef = useRef(null);

  return(
    <CheckForm
      label={'Make this decision read-only'}
      disabled={!submit}
      type={'checkbox'}
      className='mb-0 mt-2'
      checked={read_only === true}
      onClick={() => onChangeSettings({ read_only: !read_only }, checkboxRef, setSubmit, false)}
      ref={checkboxRef}
    />
  )
};

export default ReadOnlyInput;
