import {isPresent} from "../helpers/common";
import {ChoiceEntryType} from "./decision";

export const TEMPLATE_TYPE = window.TreeLayoutConstants.template_amission.share_type.template;
export const COLLABORATOR_TYPE = window.TreeLayoutConstants.template_amission.share_type.collaborator;
export const DECISION_TYPE = window.TreeLayoutConstants.template_amission.share_type.decision;
export class Template {
  constructor(data) {
    this.data = data
  }

  get template_admissions() {
    return this.data.admissions.filter(a => a.share_type === TEMPLATE_TYPE)
  }

  get decision_admissions() {
    return this.data.admissions.filter(a => a.share_type === DECISION_TYPE)
  }

  get collaborator_admissions() {
    return this.data.admissions.filter(a => a.share_type === COLLABORATOR_TYPE).map(a => this._withUserData(a))
  }

  _withUserData(admission) {
    if (admission.share_type !== COLLABORATOR_TYPE) return admission;

    const userData = this.data.users.find(u => u.email === admission.user_email)
    if (isPresent(userData)) admission.user = userData
    return admission;
  }

  get isRateAndCompareDriverChoices() {
    return this.data.rate_compare_choices && !(this.data.choice_entry_widget_type === ChoiceEntryType.OPEN_ENDED);
  }
  get isOpenEnded() {
    return this.data.choice_entry_widget_type === ChoiceEntryType.OPEN_ENDED;
  }
}
