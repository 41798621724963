import { isPresent } from "../../helpers/common";
import { extractDriverName, rowCellIdKey } from "./common";
import {
  EDITABLE_COMPARISON_HEADERS,
  NOT_EDITABLE_COMPARISON_HEADERS, SALES_FACTS
} from "./ag_grid_vars";

const TEXT_COLOR = '#2477B2';
const EDITABLE_BACKGROUND_COLOR = '#E5F5FF';
const EDITABLE_CELL_STYLES = { backgroundColor: EDITABLE_BACKGROUND_COLOR };
const EDITED_FACT_CELL_STYLES = { backgroundColor: EDITABLE_BACKGROUND_COLOR, fontStyle: 'italic', color: TEXT_COLOR };
const REQUIRED_RUN_MODEL_CELL_STYLES = { backgroundColor: '#FBF5D8', fontStyle: 'italic', color: TEXT_COLOR };
const UPDATED_CELL_STYLES = { backgroundColor: '#D7F6ED', fontStyle: 'italic', color: TEXT_COLOR };
const isUpdatedCell = (params, updatedCells) => {
  const periodId = params.colDef.colId;
  return updatedCells.some(updatedCell => {
    return updatedCell.relatedPeriodIds.includes(periodId) && updatedCell.nodeId === params.node.id
    }
  );
}

const isUpdatedFactTotalCell = (forecastScenario, params, config, timeScale, editedCell) => {
  return isEditableFactTotalCell(forecastScenario, params, config, timeScale) && editedCell
}

const isNotEditableFactHeader = (cellFactHeader) => NOT_EDITABLE_COMPARISON_HEADERS.includes(cellFactHeader);

const isEditableFactHeaders = (cellFactHeader) => EDITABLE_COMPARISON_HEADERS.includes(cellFactHeader);

const isEditableFactCell = (params, config, timeScale) => {
  if(isNotEditableFactHeader(params.value)) return false;

  const subFactHeader = params.data?.sub_fact || params.node?.data?.sub_fact;
  if(isNotEditableFactHeader(subFactHeader)) return false;

  const factHeader = extractDriverName(params.data?.Facts || params.node?.data?.Facts);
  return config.isEditableDriver(timeScale,  extractDriverName(params.node.key) || factHeader)
}

const isEditableFactTotalCell = (forecastScenario, params, config, timeScale) => {
  if(isNotEditableFactHeader(params.value)) return false;

  const subFactHeader = params.data?.sub_fact || params.node?.data?.sub_fact;
  if(isNotEditableFactHeader(subFactHeader)) return false;

  if(forecastScenario.isEditableTimeScale) {
    return config.isEditableDriver(timeScale, extractDriverName(params.node.key))
  } else {
    return true
  }
}

export const isEditableCell = (params, config, timeScale) => {
  if(isNotEditableFactHeader(params.value)) return false;

  const subFactHeader = params.data?.sub_fact || params.node?.data?.sub_fact;
  if(isNotEditableFactHeader(subFactHeader)) return false;

  const factHeader = extractDriverName(params.data?.Facts || params.node?.data?.Facts);
  return (config.isEditableDriver(timeScale,  factHeader) ||
    (isEditableFactHeaders(params.value) && config.isEditableDriver(timeScale,  extractDriverName(params.node?.parent?.key))) ||
    (isEditableFactHeaders(subFactHeader) && config.isEditableDriver(timeScale, factHeader)))
}

const isRequiredRunModel = (params, runModelCellsToHighlight) => {
  const periodId = params.colDef.colId;

  return runModelCellsToHighlight.some(runModelCell =>
    runModelCell.nodeId === params.node.id && runModelCell.relatedPeriodIds.includes(periodId)
  );
};

const isEditedCell = (params, editedCells, editedCellsIds) => {
  const periodId = params.colDef.colId;

  return editedCellsIds.includes(rowCellIdKey(params)) ||
    params.node.allLeafChildren?.some(child =>
    editedCells.some(editedCell =>
      editedCell.nodeId === child.id && editedCell.relatedPeriodIds.includes(periodId)
    )
  );
};

const isEditedFactCell = (params, editedCellsRowIds) =>
  params.node.allLeafChildren?.some(child => editedCellsRowIds.includes(child.id)) ||
  editedCellsRowIds.includes(params.node.id)

export const styledPeriodColumn = (forecastScenario, params, editedCells, editedCellsIds, updatedCells, config, timeScale, runModelCellsToHighlight) => {
  const aggregatedFact = forecastScenario.aggregatedFact;
  if(isPresent(aggregatedFact) && !SALES_FACTS.includes(aggregatedFact.displayName)) return {};

  if (isRequiredRunModel(params, runModelCellsToHighlight)) {
    return REQUIRED_RUN_MODEL_CELL_STYLES;
  }
  if (isUpdatedCell(params, updatedCells)) {
    return UPDATED_CELL_STYLES;
  }
  const editedCell = isEditedCell(params, editedCells, editedCellsIds);
  if (isUpdatedFactTotalCell(forecastScenario, params, config, timeScale, editedCell)) {
    return UPDATED_CELL_STYLES;
  }
  if (isEditableCell(params, config, timeScale) && editedCell) {
    return EDITED_FACT_CELL_STYLES;
  }
  if (isEditableCell(params, config, timeScale)) {
    return EDITABLE_CELL_STYLES;
  }
  if (!isEditableCell(params, config, timeScale) && editedCell) {
    return EDITED_FACT_CELL_STYLES;
  }
}

export const styledFactsColumn = (params, editedCellsRowIds, config, timeScale) => {
  if (isEditableFactCell(params, config, timeScale) && isEditedFactCell(params, editedCellsRowIds)) {
    return EDITED_FACT_CELL_STYLES;
  }
  if (isEditableFactCell(params, config, timeScale)) {
    return EDITABLE_CELL_STYLES;
  }
}
