import { isBlank } from "../../helpers/common";
import { extractDriverName } from "./common";
import { FACT_COMPARISON_HEADERS_NAMES, FACTS_HEADER, SUB_FACT_HEADER } from "./ag_grid_vars";

// Facts filters
const factsSortComparator = (valueA, valueB, driversForSelect) => {
  const factASortOrder = driversForSelect.find(col => col.displayName === extractDriverName(valueA))?.sortOrder;
  const factBSortOrder = driversForSelect.find(col => col.displayName === extractDriverName(valueB))?.sortOrder;
  return factASortOrder - factBSortOrder;
};

export const filterValueGetter = (params) => {
  if(isBlank(params.data[FACTS_HEADER])) return params.data[SUB_FACT_HEADER];
  return params.data[FACTS_HEADER];
};

export const factsFilterParams = (config, forecastScenario) => {
  const timeScaleConfig = config.timeScales.find(ts => ts.key === forecastScenario.timeScale);
  const driversForSelect = config.factsCForChartsolumns.filter(dr => dr.isUsedForAggregatingSubTotals(forecastScenario.timeScale, timeScaleConfig) && dr.visibleFor(forecastScenario.timeScale));
  return {
    values: [...driversForSelect.map(col => forecastScenario.driverWithMeasure(col)), ...FACT_COMPARISON_HEADERS_NAMES],
    comparator: (a, b) => factsSortComparator(a, b, driversForSelect),
    debounceMs: 1000,
    context: { forecastScenario }
  };
};

// Cmu columns filters
export const cmuColumnsFilterParams = (config, forecastScenario, groupField) => {
  const cmuColumnValues = config.cmuColumns.find(column => column.name === groupField).values;
  const filteredCmus = Object.fromEntries(
    Object.entries(cmuColumnValues).filter(([cmuId, _values]) => {
      return forecastScenario.cmus.some(cmus => cmus.includes(Number(cmuId)));
    })
  );
  const categoriesForSelect = Object.values(filteredCmus);
  return {
    values: categoriesForSelect,
    comparator: (a, b) => a.localeCompare(b),
    debounceMs: 1000,
    context: { forecastScenario }
  };
};
