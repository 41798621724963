import $ from 'jquery';
import React, {useState, useRef, useMemo} from 'react';
import {connect} from "react-redux";
import DataSource from "../../models/data_source";
import { BaseDropdownBtn } from "../../common/BaseHamburgerBtn";
import { BtnDropdownToggleSmallRound } from "../../common/dropdowns";
import UploadingItem from "./UploadingItem";
import StyledDropzone from "./DropFiles";
import { generateDataSourceUploadState } from "../../helpers/uploads_callbacks";
import SourceDescription from "./SourceDescription";
import SourceIcon from "./SourceIcon";
import {setLinkPanelOpen} from "../../store/sidebar/actions";
import {isSafari} from "react-device-detect";
import {
  enteredDSightLinksIframeRendererEffect,
  externalLinksIframeRendererEffect,
  onEnterPress,
} from "./helpers";
import Alerts from "../../../js/alerts";
import UploadedImagesModal from "../modals/UploadedImagesModal";
import {isOwnHostLink} from "../../helpers/link_helpers";
import {isDecisionSetDecision} from "../../helpers/decision_helpers";
import {addSourceToFlow} from "../../store/decision_set/actions";
import DecisionSet from "../../models/decision_set";
import {isTemplateSetTemplate} from "../../helpers/template_helpers";
import {isBlank, isPresent} from "../../helpers/common";
import {addSourceToTemplateFlow} from "../../store/template_set/actions";
import {DURATION} from "../../alerts";
import {showAlert} from "../../store/alerts/actions";
import {isHistoricalFlow} from "../../helpers/wizard_helpers";
import {isNonUser} from "../header/buttons/helpers";


const SourceMenu = ({
                      current_user, source, sourceObj,
                      fromDriver, fromDecisionPanel, fromRecommendationPanel,
                      sourceMenuEvents,
                      sourceMenuSelect, hideOpenInPanelMenu,
                      hideReplace, hideAddToFlow
                    }) =>
  <BtnDropdownToggleSmallRound id={`source-menu-dropdown-${source.slug}`} onSelect={sourceMenuSelect}>
    <BaseDropdownBtn eventKey={sourceMenuEvents.openLinkPanel} title="Open in a panel" hidden={hideOpenInPanelMenu()} />
    <BaseDropdownBtn eventKey={sourceMenuEvents.setEditSource} title="Edit display name" />
    <BaseDropdownBtn eventKey={sourceMenuEvents.setEditSourceLink} title="Edit link" hidden={!sourceObj.isLink} />
    <BaseDropdownBtn eventKey={sourceMenuEvents.onReplaceFile} title="Replace file" hidden={hideReplace()} />
    <BaseDropdownBtn eventKey={sourceMenuEvents.addSourceToFlow} title="Add to decision flow" hidden={hideAddToFlow()} />
    <BaseDropdownBtn eventKey={sourceMenuEvents.onRemoveFromDriver} title="Remove from driver" hidden={!fromDriver || isNonUser(current_user)} />
    <BaseDropdownBtn eventKey={sourceMenuEvents.hideInDecisionPanel} title="Remove from decision" hidden={!fromDecisionPanel} />
    <BaseDropdownBtn eventKey={sourceMenuEvents.hideInRecommendationPanel} title="Remove from recommendation" hidden={!fromRecommendationPanel} />
    <BaseDropdownBtn eventKey={sourceMenuEvents.delete} title="Delete data source" bsPrefix="text-danger" />
  </BtnDropdownToggleSmallRound>

export const DescriptionRow = ({
                                 source,
                                 link, setLink = () => null,
                                 title, setTitle = () => null,
                                 onEnterPress,
                                 editSourceLink,
                                 editSource,
                                 onSave = () => null,
                                 onSaveLink = () => null,
                                 fromRecommendationPanel,
                                 fromDecisionPanel
                               }) => {
  const timeout = fromRecommendationPanel || fromDecisionPanel ? 100 : 50

  if (editSourceLink) {
    return <div className="source-description ms-2 my-auto lh-sm source-description-width"
                key={`edit-data-source-${source.slug}`}>
      <input className="form-control"
             type="text"
             placeholder="Enter a link"
             value={link}
             data-value-type="link"
             onChange={(e) => setLink(e.target.value)}
             onKeyDown={onEnterPress}
             onBlur={onSaveLink}
             onClick={(e) => e.preventDefault()}
             ref={inputElement => {
               if (inputElement) {
                 setTimeout(() => inputElement.focus(), 100);
               }
             }}
             autoComplete="off"
      />
    </div>
  }
  if (editSource) {
    return <div className="position-absolute source-description ms-2 my-auto lh-sm source-description-width"
                key={`edit-data-source-${source.slug}`}>
      <input className="form-control"
             type="text"
             placeholder="Enter a display name"
             value={title}
             data-value-type="title"
             onChange={(e) => setTitle(e.target.value)}
             onKeyDown={onEnterPress}
             onBlur={onSave}
             onClick={(e) => e.preventDefault()}
             ref={inputElement => {
               if (inputElement) {
                 setTimeout(() => inputElement.focus(), timeout);
               }
             }}
             autoComplete="off"
      />
    </div>
  }
  return <></>
}

export const openLinkPanel = (sidebar, openLinkPanelSidebar, source) => {
  if(sidebar.linkPanelSidebar) {
    openLinkPanelSidebar(!sidebar.linkPanelSidebar, source)
    setTimeout(() => { openLinkPanelSidebar(sidebar.linkPanelSidebar, source) }, 600)
  }
  openLinkPanelSidebar(!sidebar.linkPanelSidebar, source)
}

const SourceItem = ({
                      driver = { slug: null },
                      decision,
                      fromDriver = false,
                      source,
                      users,
                      key = '',
                      hideMenu = false,
                      openLinkPanelSidebar,
                      isTemplate = false,
                      forceEditMode = false,
                      isWizard = false,
                      fromDecisionPanel = false,
                      sidebar,
                      updateSource = () => {},
                      destroySource = () => {},
                      replaceSource = () => {},
                      detachReport = () => {},
                      detachScenario = () => {},
                      addSourceToFlow = () => {},
                      addSourceToTemplateFlow = () => {},
                      showAlert = () => {},
                      isPlaybook = false,
                      fromRecommendationPanel = false,
                      isDriverDetailsModal = false,
                      decision_set, current_user, wizard, template
                    }) => {
  if(fromDecisionPanel && source.hide_in_decision_panel || fromRecommendationPanel && source.hide_in_recommendation_panel) return null;

  const sourceObj = new DataSource(source);
  const prevTitle = source.title || "";
  const prevLink = source.link_url || "";
  const [title, setTitle] = useState(source.title || "");
  const [editSource, setEditSource] = useState(forceEditMode);
  const [link, setLink] = useState(source.link_url || "");
  const [editSourceLink, setEditSourceLink] = useState(false);
  const [replacingFile, setReplacingFile] = useState(false);
  const [isReplaceFile, setIsReplaceFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [canShowIframe, setCanShowIframe] = useState(false);
  const [showUploadedImagesModal, setShowUploadedImagesModal] = useState(false);
  const itemRef = useRef(null);
  const decisionSetObj = new DecisionSet(decision_set)
  const collaborators = decisionSetObj.collaborators
  const isDecisionSetUser = useMemo(() => collaborators.some(user => user.email === current_user.email), [collaborators, current_user])

  externalLinksIframeRendererEffect(sourceObj, setCanShowIframe);
  enteredDSightLinksIframeRendererEffect(source, setCanShowIframe);

  const uploadOptions = generateDataSourceUploadState(source, cancelTokenSource, setCancelTokenSource, setUploadProgress, setReplacingFile)

  if(replacingFile)
    return <UploadingItem
      id={`${source.slug}-item`}
      isTemplate={isTemplate}
      source={source}
      uploadProgress={uploadProgress}
      {...uploadOptions}
    />

  const onRemoveSource = (slug) => destroySource(slug, {}, (isTemplate || isWizard))
  const onRemoveReportSource = () => {
    if(fromDriver) return onRemoveFromDriver(source.slug)

    let data = {}
    if(fromDecisionPanel) data.hide_in_decision_panel = true;
    if(fromRecommendationPanel) data.hide_in_recommendation_panel = true;

    detachReport(source.report_slug, data, isWizard, source.slug)
  }
  const onRemoveForecastScenarioReportSource = () => {
    if(fromDriver) return onRemoveFromDriver(source.slug)

    let data = {}
    if(fromDecisionPanel) data.hide_in_decision_panel = true;
    if(fromRecommendationPanel) data.hide_in_recommendation_panel = true;

    detachScenario(source.scenario_id, data, isWizard, source.slug)
  }

  const onRemoveFromDriver = (slug) => destroySource(slug, isTemplate ? { template_driver_slug: driver.slug } : { driver_slug: driver.slug }, (isTemplate || isWizard))

  const onReplaceFile = () => {
    $(itemRef.current).find('input[type=file]').click()
    setIsReplaceFile(true)
  }

  const hideReplace =  () => {
    if(!sourceObj.isFile) return true;
    if(isPlaybook) return true;
    if(fromDecisionPanel) return true;
    if(fromRecommendationPanel) return true;
    if(fromDriver) return true;

    return false;
  }

  const hideAddToFlow = () => {
    if(!isTemplateSetTemplate(template) && isPresent(template.slug)) return true;
    if(!isDecisionSetDecision(decision) && isBlank(template.slug)) return true;

    return !!(!isDecisionSetUser && isBlank(template.slug));
  }

  (!isTemplateSetTemplate(template) && isPresent(template.slug)) || (!isDecisionSetDecision(decision) && isBlank(template.slug)) || (!isDecisionSetUser && isBlank(template.slug))
  const removeSource = (type) => {
    Alerts.warning({
      title: `Are you sure you would like to ${type}?`,
    }).then(confirmed => {
      if (confirmed) {
        if (sourceObj.isLinkedForecastScenario) {
          onRemoveForecastScenarioReportSource()
        } else if (sourceObj.isLinkedReport) {
          onRemoveReportSource()
        } else {
          onRemoveSource(source.slug)
        }
      }
    });
  };

  const onAddSourceToFlow = () => {
    const callback = (success) => {
      if(success) {
        showAlert({
          text: 'The data source has been added to the decision flow.',
          type: 'success',
          addClass: 'text-center',
          dismissible: false,
          duration: DURATION
        });
      }
    }
    isBlank(template.slug) ? addSourceToFlow(source.slug, callback) : addSourceToTemplateFlow(source.slug, callback)
  }

  const sourceMenuEvents = {
    delete: 'delete',
    hideInRecommendationPanel: 'hideInRecommendationPanel',
    hideInDecisionPanel: 'hideInDecisionPanel',
    onRemoveFromDriver: 'onRemoveFromDriver',
    onReplaceFile: 'onReplaceFile',
    setEditSourceLink: 'setEditSourceLink',
    setEditSource: 'setEditSource',
    openLinkPanel: 'openLinkPanel',
    addSourceToFlow: 'addSourceToFlow'
  }

  const sourceMenuSelect = (eventKey) => {
    switch (eventKey) {
      case sourceMenuEvents.delete:
        return removeSource('delete this data source');
      case sourceMenuEvents.hideInRecommendationPanel:
        return hideInRecommendationPanel();
      case sourceMenuEvents.hideInDecisionPanel:
        return hideInDecisionPanel();
      case sourceMenuEvents.onRemoveFromDriver:
        return onRemoveFromDriver(source.slug);
      case sourceMenuEvents.onReplaceFile:
        return onReplaceFile();
      case sourceMenuEvents.setEditSourceLink:
        return setEditSourceLink(true);
      case sourceMenuEvents.setEditSource:
        return setEditSource(true);
      case sourceMenuEvents.openLinkPanel:
        return openLinkPanel(sidebar, openLinkPanelSidebar, source);
      case sourceMenuEvents.addSourceToFlow:
        return onAddSourceToFlow();
    }
  }

  const hideOpenInPanelMenu = () => {
    const isHistoricalOrNonLink = isHistoricalFlow(wizard) || !sourceObj.isLink;
    const shouldHide = !canShowIframe || (isSafari && !isOwnHostLink(source.link_url));
    return isHistoricalOrNonLink || shouldHide || isNonUser(current_user);
  };

  const onSave = () => {
    if ((title === prevTitle) && isPresent(title)) return setEditSource(false);
    if (isBlank(title) && isBlank(source.link_url)) {
      setTitle(prevTitle)
      return setEditSource(false);
    }

    updateSource(source.slug, { title: title, driver_slug: driver.slug }, (isTemplate || isWizard));
    setEditSource(false)
  };

  const hideInDecisionPanel = () => {
    updateSource(source.slug, { hide_in_decision_panel: true, driver_slug: driver.slug }, (isTemplate || isWizard));
  };

  const hideInRecommendationPanel = () => {
    updateSource(source.slug, { hide_in_recommendation_panel: true, driver_slug: driver.slug }, (isTemplate || isWizard));
  };

  const onSaveLink = () => {
    updateSource(source.slug, { link_url: link, driver_slug: driver.slug }, (isTemplate || isWizard));
    setEditSourceLink(false)
  };

  const onCancelTitle = () => {
    setEditSource(false);
    setTitle(prevTitle);
  };

  const onCancelLink = () => {
    setEditSourceLink(false);
    setLink(prevLink);
  };

  const openUploadedImagesModal = () => {
    if (!sourceObj.isImage || isDriverDetailsModal) return null

    setShowUploadedImagesModal(true)
  }

  return <React.Fragment>
    <div className={`${editSourceLink || editSource ? 'edit-source-spacing' : 'mb-2'}`} key={key} ref={itemRef} >
      <div className="data-source-row">
        <div className="d-flex position-relative">
          <DescriptionRow source={source}
                          link={link}
                          setLink={setLink}
                          setTitle={setTitle}
                          title={title}
                          onSave={onSave}
                          onSaveLink={onSaveLink}
                          onEnterPress={(e) => onEnterPress(e, onSaveLink, onSave, onCancelTitle, onCancelLink)}
                          editSource={editSource}
                          editSourceLink={editSourceLink}
                          fromRecommendationPanel={fromRecommendationPanel}
                          fromDecisionPanel={fromDecisionPanel}
          />
          <a href={sourceObj.contentUrl(decision.slug, isDriverDetailsModal)} onClick={openUploadedImagesModal} title={sourceObj.contentUrl(decision.slug)}
             target={sourceObj.contentTargetName} className={`d-flex px-0 w-100 pointer ${hideMenu ? "" : "width-cut"}`}>
            <div className={`d-flex source-icon`}>
              <div className={`align-text-top fa-layers fa-3x ${sourceObj.iconBlock}`}>
                <SourceIcon sourceObj={sourceObj} />
              </div>
            </div>
            <div className="source-description ms-2 my-auto lh-sm" hidden={editSource || editSourceLink}>
              <SourceDescription {...{ sourceObj, users, isTemplate }} />
            </div>
            <div className="icon source-link ms-auto my-auto px-2">
              <i className={sourceObj.actionIconClass+' text-primary fa-lg'} />
            </div>
          </a>
          {sourceObj.isLinkedForecastScenario ?
            <div className={`btn btn-light btn-sm btn-sm-round text-danger my-auto`}
                 onClick={() => removeSource('remove this scenario')}
                 hidden={hideMenu || !sourceObj.isLinkedForecastScenario} >
              <i className="fas fa-times w-100"/>
            </div> :
            <div className="d-flex source-actions">
              <div className="icon source-menu my-auto" hidden={hideMenu || sourceObj.isLinkedReport}>
                <SourceMenu {...{
                  current_user, source, sourceObj, fromDriver, fromDecisionPanel, fromRecommendationPanel,
                  sourceMenuEvents, sourceMenuSelect, hideOpenInPanelMenu, hideReplace, hideAddToFlow
                }} />
              </div>
              <div className={`btn btn-light btn-sm btn-sm-round text-danger my-auto`}
                   onClick={() => removeSource('remove this report')}
                   hidden={hideMenu || !sourceObj.isLinkedReport}>
                <i className="fas fa-times w-100"/>
              </div>
            </div>
          }

          {sourceObj.isFile ?
            <StyledDropzone hiddenInput={true}
                            maxFiles={1}
                            onCreateSource={(data, config, callback, updateOnlySources) => {
                              replaceSource(source.slug, data, config, callback, updateOnlySources)
                            }}
                            isWizard={isWizard}
                            isReplaceFile={isReplaceFile}
                            isTemplate={isTemplate}
                            {...uploadOptions} />
            : null}
        </div>
      </div>
    </div>
    <div className="modals">
      <UploadedImagesModal shown={showUploadedImagesModal} sourceObj={sourceObj} onClose={() => setShowUploadedImagesModal(false)} />
    </div>
  </React.Fragment>
};
const mapStateToProps = ({ template, decision, sidebar, decision_set, current_user, template_set, wizard }, { driver, source, isTemplate }) => ({
  driver, source, sidebar, decision,
  users: isTemplate ? template.users : decision.users,
  decision_set, current_user, template_set, template, wizard
});
const mapDispatchToProps = (dispatch) => ({
  openLinkPanelSidebar: (option, source) => {
    dispatch(setLinkPanelOpen(option, source));
  },
  addSourceToFlow: (slug, callback) => dispatch(addSourceToFlow(slug, callback)),
  addSourceToTemplateFlow: (slug, callback) => dispatch(addSourceToTemplateFlow(slug, callback)),
  showAlert: (data) => dispatch(showAlert(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(SourceItem);
