import React, {useEffect, useMemo, useState} from "react";
import Decision from "../../models/decision";
import Driver from "../../models/driver";
import {openAllActionUseEffect} from "../../tree_view/side_panel/helpers/accordion_helpers";
import CustomHeaderToggle from "../../tree_view/side_panel/CustomHeaderToggle";
import {ChoiceRow} from "../../tree_view/side_panel/driver_input/RateDriverChoicesInput";
import {isBlank} from "../../helpers/common";

const saveDriverChoiceStateCallback = (rateChoiceData, rating, evaluation, driverData, driverObj, setDriverData) => {
  useEffect(() => {
    if (isBlank(driverData.driver_choices)) return;

    const { choice_slug } = rateChoiceData;
    const updatedDriverChoices = driverData.driver_choices.map(choice => {
      if (choice.choice_slug === choice_slug) {
        return { ...choice, rating, evaluation };
      }
      return choice;
    });
    const updatedDriverData = {...driverData, driver_choices: updatedDriverChoices};
    setDriverData(updatedDriverData);
  }, [rating, evaluation]);
}

const RateDriverChoicesInput = ({ decision, drivers, driver, driverData, setDriverData }) => {
  const decisionObject = useMemo(() => new Decision(decision, drivers), [decision]);
  const [openAll, setOpenAll] = useState(false);
  const [openIndexes, setOpenIndexes] = useState([]);
  const driverObj = useMemo(() => new Driver(driver.driver, [], {}, decisionObject, true), [driver, decisionObject]);
  const allRateChoicesSlugs = useMemo(() => driverObj.sortedDriverChoicesRatings.map((rateChoiceData) => rateChoiceData.choice_slug), [driverObj]);

  openAllActionUseEffect(openAll, openIndexes, allRateChoicesSlugs, {setOpenIndexes, setOpenAll});

  if (decisionObject.isRateAndCompareDriverChoicesHidden) return null;

  const saveDriverChoiceState = (rateChoiceData, rating, evaluation) =>
    saveDriverChoiceStateCallback(rateChoiceData, rating, evaluation, driverData, driverObj, setDriverData)

  useEffect(() => {
    let driver_choices = []
    driverObj.sortedDriverChoicesRatings.forEach(d_c => {
      driver_choices.push({ choice_slug: d_c.choice_slug, rating: '', evaluation: null })
    })
    setDriverData(Object.assign({}, driverData, {driver_choices}))
  }, []);

  return <div className="mb-3 px-3">
    <CustomHeaderToggle {...{openAll, setOpenAll}}>
      <h3>{ driverObj.question }</h3>
    </CustomHeaderToggle>
    {
      driverObj.sortedDriverChoicesRatings.map((rateChoiceData, index) =>
        <ChoiceRow key={`choice-${rateChoiceData.choice_slug}-${index}`}
                   {...{rateChoiceData, driverObj, openIndexes, setOpenIndexes, saveDriverChoiceState}} isResetInitData={true} />)
    }
  </div>
}

export default RateDriverChoicesInput;
