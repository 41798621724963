import DecisionCategory from "./decision_category";

export const OPEN = window.TreeLayoutConstants.report_property.input_types.open;
export const PICK_ONE_STATIC = window.TreeLayoutConstants.report_property.input_types.pick_one_static;
export const PICK_ONE_TYPEAHEAD = window.TreeLayoutConstants.report_property.input_types.pick_one_typeahead;
export const PICK_ALL_THAT_APPLY = window.TreeLayoutConstants.report_property.input_types.pick_all_that_apply;

export const NAME_LIMIT = window.TreeLayoutConstants.report_property.max_name_length;

export const REPORT_PROPERTY_TYPES = window.TreeLayoutConstants.report_property.input_types

export default class ReportProperty extends DecisionCategory {
  constructor(data) {
    super(data);
  }
  get isPickOne() {
    return this.input_type === PICK_ONE_STATIC;
  }

  get isPickOneTypehead() {
    return this.input_type === PICK_ONE_TYPEAHEAD;
  }

  get isPickAllThatApply() {
    return this.input_type === PICK_ALL_THAT_APPLY;
  }
  get isOpen() {
    return this.input_type === OPEN;
  }

  get openInputOption() {
    return this.options[0];
  }
}
