import React from 'react';
import { connect } from "react-redux";
import {
  setDecisionInputSidebarOpen, setDriverInputSidebarOpen,
  setRecommendationInputSidebarOpen,
  updateRecordDecision
} from "../../../store/sidebar/actions";
import { updateUserData } from "../../../store/current_user/actions";
import ReadOnlyInput from "../decision_input/ReadOnlyInput";
import AllowCollaboratorsEditTreeInput from "../decision_input/AllowCollaboratorsEditTreeInput";
import AllowManageCollaboratorsInput from "../decision_input/AllowManageCollaboratorsInput";
import {isDecider, isDeciderReadOnly} from "../../../helpers/user_helpers";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";

const SettingsSection = ({ user, decision, updateRecordDecision, updateUserData,
                           setRecommendationInputSidebarOpen, setDecisionInputSidebarOpen, setDriverInputSidebarOpen}) => {
  const isDeciderUser = isDecider(user)
  const isDeciderReadOnlyUser = isDeciderReadOnly(user)

  if (!isDeciderUser && !isDeciderReadOnlyUser || isDSightDecision(decision)) return null;

  const onChangeSettings = (data, checkboxRef, setSubmit, ignoreSideBarUpdate = true) => {
    setSubmit(false);
    const callback = (success, read_only) => {
      if (success) {
        if (!ignoreSideBarUpdate) {
          setRecommendationInputSidebarOpen(false);
          setDecisionInputSidebarOpen(false);
          setDriverInputSidebarOpen(false);
        }
        updateUserData({ decision_role: read_only ? 'decider_read_only' : 'decider' });
        checkboxRef.current && checkboxRef.current.scrollIntoView({ behavior: 'smooth' });
        setSubmit(true);
      }
    };
    updateRecordDecision(data, callback, ignoreSideBarUpdate);
  };

  return <div className={'my-2'}>
    <h3>Settings</h3>
    <ReadOnlyInput {...{decision, onChangeSettings}} />
    <AllowCollaboratorsEditTreeInput {...{decision, onChangeSettings}} />
    <AllowManageCollaboratorsInput {...{decision, onChangeSettings}} />
  </div>
};

const mapStateToProps = ({ current_user, decision }) => ({ user: current_user, decision });
export default connect(mapStateToProps, {
  updateRecordDecision, updateUserData, setRecommendationInputSidebarOpen,
  setDecisionInputSidebarOpen, setDriverInputSidebarOpen
})(SettingsSection);
