import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isBlank, isPresent} from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import {ModalDoneFooter} from "../../common/modals";
import {submitEntryCallback} from './entry_modal/helpers';
import ModalHeaderTitle from "../../common/ModalHeaderTitle";
import {resetDecisionSetData} from "../../store/decision_set/common_actions";
import PollDateInput from "../side_panel/poll_input/PollDateInput";
import * as moment from "moment/moment";
import {RichTextEditor} from "../../common/RichTextEditor";
import {updateDecision} from "../../store/modals/actions";
import {saveSideBarData} from "../../store/sidebar/common_actions";

const decisionUpdateData = (poll_due_date, pollNotes) => {
  return {
    template: {
      poll_due_date,
      poll_notes: pollNotes
    }
  };
}
export const openDecisionPollModal = ({ openModal, decision, ...opts }) => openModal({ ...opts, type: 'DecisionPollModal', decision })

const DecisionPollModal = ({
                              show, onClose,
                              decision,
                              key = '', updateDecision, saveSideBarData
                            }) => {
  if(isBlank(decision)) return null;

  const initDate = isPresent(decision.poll_due_date) ? moment(decision.poll_due_date) : null;
  const defaultDate = moment().add(1, 'day');
  const [pollDueDate, setPollDueDate] = useState(initDate || defaultDate);
  const [pollNotes, setPollNotes] = useState(decision.poll_notes || '');
  const disabledSubmit = () => isBlank(pollDueDate);
  const poll_due_date = isPresent(pollDueDate) ? moment(pollDueDate).toString() : null

  const submissionCallback = submitEntryCallback(updateDecision,
    decisionUpdateData(poll_due_date, pollNotes), onClose, _,
    [saveSideBarData({ decision: {...decision, poll_due_date, poll_notes: pollNotes }})]
  );

 useEffect(() => {
   show && isBlank(pollNotes) && setPollNotes(decision?.poll_notes || '');
 }, [show])

  return <Modal key={key} backdrop="static" size="lg" show={show} onHide={onClose}>
    <Modal.Body>
      <form>
        <ModalHeaderTitle {...{ onClose, title: 'Decision poll' }} />
        <PollDateInput decision={decision} pollDueDate={pollDueDate} setPollDueDate={setPollDueDate} />
        <h3>Notes</h3>
        <RichTextEditor quillId={'PollNotes'} value={pollNotes} setValue={setPollNotes}
                        placeholder="Enter any comments or instructions for completing the poll" />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={submissionCallback} disabled={disabledSubmit()} />
  </Modal>
};

const mapStateToProps = ({ modal }) => ({ decision: modal.decision })
export default connect(mapStateToProps, { resetDecisionSetData, updateDecision, saveSideBarData })(DecisionPollModal);
