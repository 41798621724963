import React from "react";
import { isFuturePeriod } from "../../models/forecast/ForecastScenario";
import {isBlank, isPresent, uniqueBy} from "../../helpers/common";
import FactsCellRenderer from "../table_components/FactsCellRenderer";
import {aggregatedFact, extractDriverName, parseFormatData, parseRowCellId, valueFormatter} from "./common";
import { isEditableCell } from "./ag_grid_cell_style";
import {
  FACTS_HEADER,
  HIDDEN_COLUMNS,
  SIMULATED_FACT_HEADER,
  GROUP_COL_ID_SUFFIX,
  VALUE_SALES_FACT, FACTS_GR_COL_ID, ROW_DRIVER_ID_KEY, SUB_FACT_HEADER
} from "./ag_grid_vars";
import CustomPeriodCellRenderer from "../table_components/CustomPeriodCellRenderer";
import { aggregatedByCmu } from "../../models/forecast/aggregastion";
import { cmuColumnsFilterParams, factsFilterParams, filterValueGetter } from "./ag_grid_filters";
import { ForecastScenarioRow } from "../../models/forecast/ForecastScenarioRow";
import CustomFilter from "./CustomFilter";

const BASE_HEADER_OPTIONS = {
  sortable: true,
  filter: true,
  resizable: true
}
const CHOOSE_COLUMN_ITEM = 'columnChooser';
const ROW_GROUP_MENU_ITEM = 'rowGroup';
const ROW_UNGROUP_MENU_ITEM = 'rowUnGroup';
const EXPAND_ALL_MENU_ITEM = 'expandAll';
const CONTRACT_ALL_MENU_ITEM = 'contractAll';
const EXCLUDE_MENU_ITEMS = [
  CHOOSE_COLUMN_ITEM, ROW_GROUP_MENU_ITEM, ROW_UNGROUP_MENU_ITEM
];

export const timePeriodNameParse = (name) => {
  const splitNameArray = name.split('_');
  if (splitNameArray.length !== 2) return name;

  return splitNameArray.reverse().join(' ');
};

const totalAggOutput = (allValues, allRows, value, decimal) => {
  return {
    allValues,
    allRows,
    toNumber: () => value,
    toString: () => valueFormatter(value, { decimal })
  };
};

const getPeriod = (forecastScenario, timeScale, colId) => {
  return forecastScenario.allTimeScalePeriods(timeScale).find(period => period.id === colId);
};

const getDriverIds = (config, aggFact) => {
  const drivers = uniqueBy([...config.outputColumns, aggFact], 'id');
  return drivers.map(driver => driver.id.toString());
};

const processLeafChildren = (params, leafChildren, aggFact, driverIds, colField) => {
  let values = [];
  let allRows = [];
  let prepareRowAttrs = {};

  leafChildren.forEach((leaf, index) => {
    const driverName = extractDriverName(leaf.data[FACTS_HEADER]);
    const subFact = leaf.data[SUB_FACT_HEADER];
    const value = leaf.data[colField];
    const driverId = parseRowCellId(leaf.data[ROW_DRIVER_ID_KEY]).driverId;

    if (driverName === aggFact.displayName && (isBlank(subFact) || subFact === SIMULATED_FACT_HEADER)) {
      values.push(leaf);
    }
    if (typeof params.values[index] === 'object' && params.values[index] !== null) {
      allRows.push(params.values[index].allRows);
      allRows = allRows.flat();
    }
    if (driverIds.includes(driverId)) {
      prepareRowAttrs[driverId.toString()] = [value];
    }
  });

  return { values, allRows, prepareRowAttrs };
};

const aggregateValues = (values, colField) => {
  let allValues = [];

  values.forEach((data) => {
    const value = data.data[colField];
    if (typeof value === 'object' && value !== null) {
      allValues.push(value.allValues).flat();
    } else if (typeof value === 'number') {
      allValues.push({ value });
    }
  });

  return allValues;
};

export const totalAggFunc = (params, forecastScenario) => {
  const filteredAllLeafChildren = params.rowNode.allLeafChildren.filter(leaf =>
    !leaf.group && (isBlank(leaf.data[SUB_FACT_HEADER]) || leaf.data[SUB_FACT_HEADER] === SIMULATED_FACT_HEADER)
  );
  const period = getPeriod(forecastScenario, params.colDef.context.timeScale, params.colDef.colId);

  if(isBlank(filteredAllLeafChildren[0]?.data) || isBlank(period)) return null;

  const config = forecastScenario.config;
  const aggFact = aggregatedFact(config, forecastScenario) || forecastScenario.findFactByDriverName(VALUE_SALES_FACT);

  if (params.rowNode.leafGroup) {
    return totalAggOutput([{ value: params.values[0] }], [], params.values[0], parseFormatData(params, forecastScenario, config).decimal);
  }
  const driverIds = getDriverIds(config, aggFact);
  const { cmus } = parseRowCellId(filteredAllLeafChildren[0].data[ROW_DRIVER_ID_KEY]);
  const convertedCmus = cmus.split(',').map(Number);
  const { values, allRows, prepareRowAttrs } = processLeafChildren(params, filteredAllLeafChildren, aggFact, driverIds, params.colDef.field);

  if (isPresent(prepareRowAttrs) && !allRows.some(row => row.cmusGroupKey === [...convertedCmus, aggFact.id].join('_') )) {
    allRows.push(new ForecastScenarioRow({ attributes: { cmus: convertedCmus, data: prepareRowAttrs } }, forecastScenario, aggFact));
  }

  const allValues = aggregateValues(values, params.colDef.field);
  const aggregatedValue = aggregatedByCmu(allRows, aggFact, period, config, allValues);

  return totalAggOutput(allValues, allRows, aggregatedValue.value, parseFormatData(params, forecastScenario, config).decimal);
};

const factsMenuItems = (params) => {
  const menuItems = [];
  const excludeItems = [...EXCLUDE_MENU_ITEMS, EXPAND_ALL_MENU_ITEM, CONTRACT_ALL_MENU_ITEM];
  params.defaultItems.forEach((item) => {
    if (excludeItems.indexOf(item) < 0) {
      menuItems.push(item);
    }
  });
  return menuItems;
}

const customMenuItems = (params) => {
  return params.defaultItems.filter(item => !EXCLUDE_MENU_ITEMS.includes(item)).map(item => {
    if ([EXPAND_ALL_MENU_ITEM, CONTRACT_ALL_MENU_ITEM].includes(item)) {
      const isExpanded = item === EXPAND_ALL_MENU_ITEM;
      const name = isExpanded ? 'Expand All Rows Groups' : 'Collapse All Rows Groups';
      return {
        name,
        action: () => {
          params.api.forEachNode(node => {
            if (node.field === params.column.colDef.headerName) {
              params.api.setRowNodeExpanded(node, isExpanded);
            }
          });
        }
      };
    }
    return item;
  });
}

const groupedColDefs = ({ forecastScenario, config, forecastBenchmarkScenario }) => {
  const colDefs = forecastScenario.groupFields.map(groupField =>
    ({
      headerName: groupField,
      showRowGroup: groupField,
      colId: `${groupField}-${GROUP_COL_ID_SUFFIX}`,
      cellRenderer: 'agGroupCellRenderer',
      filter: CustomFilter,
      filterParams: cmuColumnsFilterParams(config, forecastScenario, groupField),
      filterValueGetter: (params) => params.data[groupField],
      cellRendererParams: { suppressCount: true },
      width: 170,
      sort: 'asc',
      mainMenuItems: customMenuItems
    })
  )
  colDefs.push(
    {
      headerName: 'Facts',
      colId: FACTS_GR_COL_ID,
      minWidth: 250,
      showRowGroup: 'Facts',
      filter: CustomFilter,
      filterParams: factsFilterParams(config, forecastScenario),
      filterValueGetter: (params) => filterValueGetter(params),
      cellRenderer: (params) => <FactsCellRenderer {...{ params, config, forecastScenario, forecastBenchmarkScenario } } />,
      cellRendererParams: { suppressCount: true },
      type: 'styledFactsColumn',
      aggFunc: 'first',
      valueGetter: (params) => {
        if (params.data) {
          return params.data[SUB_FACT_HEADER];
        }
      },
      mainMenuItems: factsMenuItems
    }
  )
  return colDefs;
}

export const getPeriodId = (periods = [], field = '') => periods.find(p => p.name === field).id;

export const preparedColDefs = ({ forecastScenario, timeScale, config, ...opts }) => {
  const periods = forecastScenario.periods();
  const isEditableTimeScale = forecastScenario.isEditableTimeScale;
  return groupedColDefs({ forecastScenario, config, ...opts }).concat(forecastScenario.viewHeaders.map(field => {
    let definitions = { field, ...BASE_HEADER_OPTIONS };
    definitions.context = { timeScale };
    if (HIDDEN_COLUMNS.includes(field)) {
      definitions.hide = true;
      }
    if (forecastScenario.groupFields.includes(field)) {
      definitions.hide = true;
      definitions.rowGroup = true;
    }
    if(FACTS_HEADER === field) {
      definitions.rowGroup = true;
    }
    if(forecastScenario.periodHeadersNames.includes(field)) {
      const periodId = getPeriodId(periods, field);
      definitions.headerName = timePeriodNameParse(field);
      definitions.colId = periodId;
      definitions.cellRenderer = (params) =>
        <CustomPeriodCellRenderer params={params} forecastScenario={forecastScenario} />;
      definitions.aggFunc = 'totalAggFunc';
      definitions.filter = 'agNumberColumnFilter';
      definitions.width = 120;
      if(isEditableTimeScale && isFuturePeriod(forecastScenario, periodId)) {
        definitions.editable = (params) => isEditableCell(params, config, timeScale);
        definitions.type = ['editableColumn', 'styledPeriodColumn'];
      } else if(!isEditableTimeScale) {
        definitions.type = ['styledPeriodColumn'];
      }
    }
    return definitions;
  }
  )).flat()
}
