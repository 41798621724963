import { totalUsersVotes } from "./user_helpers";
import { isDSightDecision } from "./home_decision_helpers";
import Decision from "../models/decision";
import Recommendation from "../models/recommendation";
import { isBlank } from "./common";
import {choiceWithUserVotes, collectRecommendationUsersVotes, collectUsersVotes} from "./decision_helpers";

export const isVoted = (decision) => {
  const usersChoices = userVotesChoicesCallback(decision)
  return totalUsersVotes(usersChoices) > 0
}
export const userVotesChoicesCallback = (decision) => {
  if (isDSightDecision(decision)) return [];

  const decisionObj = new Decision(decision)
  const recommendationObj = new Recommendation(decision.recommendation)
  const isRecommendationFlow = decisionObj.isRecommendationFlow
  const choices = isRecommendationFlow ? recommendationObj.recommendationChoices : decisionObj.decisionChoices
  if (isBlank(choices)) return [];

  return new Decision(decision).isRecommendationFlow ?
    collectRecommendationUsersVotes(decision.recommendation) :
    collectUsersVotes(decision)
}

export const filterAndSortPollChoices = (choices) =>
  choices
    .filter(choice => !choice.hide_in_choices_section && !choice.open_ended)
    .map(choice => choiceWithUserVotes(choice))
    .sort((a, b) => {
      if (a.rank_points === b.rank_points) {
        if (a.created_at > b.created_at) return 1;
        if (a.created_at < b.created_at) return -1;
      }
      return a.rank_points > b.rank_points ? -1 : 1;
    });
