import * as moment from "moment";

export const PICK_ONE_STATIC = window.TreeLayoutConstants.decision_category.input_types.pick_one_static;
export const PICK_ONE_TYPEAHEAD = window.TreeLayoutConstants.decision_category.input_types.pick_one_typeahead;
export const PICK_ALL_THAT_APPLY = window.TreeLayoutConstants.decision_category.input_types.pick_all_that_apply;

export const NAME_LIMIT = window.TreeLayoutConstants.decision_category.max_name_length;

export const DECISION_CATEGORY_TYPES = window.TreeLayoutConstants.decision_category.input_types

export const sortOptions = (a, b) => {
  const aOrder = a.order || Infinity;
  const bOrder = b.order || Infinity;
  const aCreatedAt = moment(a.created_at);
  const bCreatedAt = moment(b.created_at);
  if(aOrder === bOrder)
  {
    return (aCreatedAt < bCreatedAt) ? -1 : (aCreatedAt > bCreatedAt) ? 1 : 0;
  }
  else
  {
    return (aOrder < bOrder) ? -1 : 1;
  }
}

const sortAsc = (a, b) => {
  return (b.label.toLowerCase() < a.label.toLowerCase()) ? 1 : -1;
}

export default class DecisionCategory {
  constructor(data) {
    this.data = data;
  }

  get options() {
    return this.data.options;
  }

  get selectOption() {
    return { value: this.data.slug, label: this.data.name }
  }

  get input_type() {
    return this.data.input_type;
  }

  get sortedOptions() {
    return this.options.map(option => ({ value: option.id, label: option.description })).sort(sortOptions);
  }

  get optionsDataSortedAsc() {
    return this.options.map(option => ({ value: option.id, label: option.description })).sort(sortAsc);
  }

  get optionsData() {
    return this.options.map(option => ({ value: option.id, label: option.description }));
  }

  get isPickOne() {
    return this.input_type === PICK_ONE_STATIC;
  }

  get isPickOneTypehead() {
    return this.input_type === PICK_ONE_TYPEAHEAD;
  }

  get isPickAllThatApply() {
    return this.input_type === PICK_ALL_THAT_APPLY;
  }
}
