import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { hideDSightTab, TABS_URLS } from "../../helpers/home_helpers";
import {
  isHomepageSectionLoaded,
  isHomepageSectionLoading,
  loadDSightData,
  needToLoadHomepageSection
} from "../../store/homepage/actions";
import { Loader } from "../../common/Loader";
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import { Goal } from "../../models/d_sight/goal";
import GoalPage from "./dsight/GoalPage";
import Cookies from "js-cookie";

const isOnGoalsTab = (home, match) => match.params?.tab === TABS_URLS.dsight || (home.current_tab === 'dsight' && isBlank(match.params?.tab))

export const DSightTab = ({
                            home, current_user, current_org, match,
                            loadDSightData
                          }) => {
  if (hideDSightTab(current_user, current_org)) return null;
  const { id: goal_id } = useParams();
  const askCloverpopEnabled = home?.dsight?.data?.ask_cloverpop_enabled;
  const goals = home.dsight.data.goals || []
  const products = qSortArray(
    (home.dsight.data.products || []).filter(product => {
      const goal = goals.find(goal => goal.id === product.value)
      return isPresent(goal?.force_display) || isPresent(goal?.insights)
    }), true, ({ data_set_id, sort_order }) => [sort_order, data_set_id]
  )
  const filteredGoals = products.map(p => goals.find(g => p.value === g.id)).filter(isPresent)

  const history = useHistory();
  const [selectedGoal, setSelectedGoal] = useState(filteredGoals.length === 1 ? filteredGoals[0] : null);
  const [selectedProduct, setSelectedProduct] = useState(products.length > 0 ? products[0].value : null);

  const setGoalIdInUrl = (goal) => {
    const newSelectedProduct = products.find(p => p.value === goal.id)
    if (newSelectedProduct) {
      setSelectedProduct(newSelectedProduct.value)
      history.push(`/homepage/${TABS_URLS.dsight}/${goal.id}`);
    }
  }

  useEffect(() => {
    if (needToLoadHomepageSection(home, 'dsight')) loadDSightData({ goal_id: goal_id })
  }, [home.dsight.loaded])

  useEffect(() => {
    if(goal_id && home.dsight.goal_id !== goal_id) loadDSightData({ goal_id: goal_id })
  }, [goal_id]);

  useEffect(() => {
    if (home.dsight.loaded && isOnGoalsTab(home, match)) {
      if (isBlank(match?.params?.id)) {
        setSelectedGoal(null);
        setSelectedProduct(null)
      }
      if (goals.length > 0 && isBlank(match?.params?.id)) {
        const lastGoalId = Cookies.get('selected-dsight-product')
        if (isPresent(lastGoalId)) {
          const lastGoal = goals.map(g => new Goal(g)).find(g => g.id === parseInt(lastGoalId))
          if (isPresent(lastGoal) && products.some(p => p.value === lastGoal.id)) {
            return setGoalIdInUrl(lastGoal);
          }
        }
        setGoalIdInUrl(new Goal(filteredGoals[0]));
      }
    }
  }, [home.dsight.loaded, match.params?.tab, match.params?.id])

  useEffect(() => {
    if (home.dsight.loaded && isOnGoalsTab(home, match) && isPresent(match?.params?.id)) {
      const goal = goals.map(g => new Goal(g)).find(g => g.id === parseInt(match?.params?.id))
      if(isBlank(goal)) {
        history.push(`/homepage/${TABS_URLS.dsight}`);
      } else {
        const newSelectedProduct = products.find(p => p.value === goal.id)
        setSelectedProduct(newSelectedProduct.value)
        setSelectedGoal(goal.data)
      }
    }
  }, [home.dsight.loaded, match.params?.id, setSelectedGoal])

  return <>
    <div className="mx-auto dsight-container">
      <div className="row" hidden={isHomepageSectionLoaded(home, 'dsight')}>
        <Loader />
      </div>
      <div className="row" hidden={isHomepageSectionLoading(home, 'dsight')}>
        <div className="col" hidden={isBlank(home.dsight.data)}>
          {
            isPresent(selectedGoal) ?
              <GoalPage {...{ selectedGoal, goals, selectedProduct, products, askCloverpopEnabled }} /> :
              <Loader />
          }
        </div>
        <div className="col" hidden={isPresent(home.dsight.data)}>
          Contact support to sync D-Sight data
        </div>
      </div>
    </div>
  </>
}
const mapStateToProps = ({ home, current_user, current_org }) => ({ home, current_user, current_org });
export default connect(mapStateToProps, { loadDSightData })(DSightTab);
