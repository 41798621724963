import React, { useEffect } from 'react';
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { isBlank } from "../../../helpers/common";
import { getMemorizedOptions } from "../../helpers/period_helpers";
import * as moment from "moment";

const isWithinOptDate = (opt, date) =>
  moment(date) >= moment(opt.start_date) && moment(date) <= moment(opt.end_date)

const PeriodSelectors = ({
                           to, setTo, from, setFrom, options,
                           disabled = false,
                           warning= null, nextOptionsLimit = false
                         }) => {
  const { validToOptions, validFromOptions } = getMemorizedOptions({ from, to, options, nextOptionsLimit })
  useEffect(() => {
    if (validFromOptions.length > 0 && isBlank(validFromOptions.find(opt => isWithinOptDate(opt, from)))) {
      setFrom(validFromOptions[0].value)
    }
  }, [validFromOptions, from]);
  useEffect(() => {
    if (validToOptions.length > 0 && isBlank(validToOptions.find(opt => isWithinOptDate(opt, to)))) {
      setTo(validToOptions[0].value)
    }
  }, [validToOptions, to]);

  return <>
    <div className="col-3 col-lg-3 px-1 mb-2">
      <div className="mb-1">From</div>
      <Select
        onChange={(option) => setFrom(option.value)}
        components={{ IndicatorSeparator: () => null }}
        styles={BorderedFilterStyle}
        placeholder="Make a selection"
        value={validFromOptions.find(opt => isWithinOptDate(opt, from) )}
        isDisabled={disabled}
        options={validFromOptions}/>
      {
        warning && <p className="text-danger py-1">{warning}</p>
      }
    </div>
    <div className="col-3 col-lg-3 px-1 mb-2">
      <div className="mb-1">To</div>
      <Select
        onChange={(option) => setTo(option.value)}
        components={{ IndicatorSeparator: () => null }}
        styles={BorderedFilterStyle}
        placeholder="Make a selection"
        value={validToOptions.find(opt => isWithinOptDate(opt, to) )}
        isDisabled={disabled}
        options={validToOptions}/>
    </div>
  </>
}
export default PeriodSelectors;
