import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { BASE_CHARTS_OPTIONS } from "../../../models/d_sight/metric_chart_helpers";

export const COLORS = ['#009988', '#CC3311', '#0077BB']

const CHART_OPTS = {
  ...BASE_CHARTS_OPTIONS,
  colors: COLORS,
  /* green, red, blue */
  lang: {
    thousandsSep: ','
  },
  chart: {
    type: 'waterfall',
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  xAxis: {
    type: 'category',
    labels: {
      style: {
        color: '#1F2F3F'
      }
    }
  },
  yAxis: {
    title: null,
    labels: {
      enabled: false
    },
    gridLineColor: '#DCE8F3'
  },
  legend: {
    enabled: false
  }
}

const TITLE_OPTS = {
  style: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '18px',
    fontWeight: '500',
    color: '#1F2F3F'
  }
}

const WaterfallChart = ({
                          title = '', measure = '', decimal = 2, data = [],
                          minCap = null, maxCap = null
                        }) => {
  const capBoundsOpts = minCap == null ? {} : {
    yAxis: {
      title: null,
      labels: {
        enabled: false
      },
      gridLineColor: '#DCE8F3',
      min: minCap,
      minRange: minCap,
      max: maxCap
    }
  }
  const chartOptions = {
    ...CHART_OPTS,
    tooltip: {
      pointFormat: [
        '<b>',
        measure.includes('$') ? '$' : '',
        '{point.y:,.',
        decimal,
        'f}',
        measure.replace('$', ''),
        '</b>'
      ].join('')
    },
    title: {
      ...TITLE_OPTS,
      text: title
    },
    ...capBoundsOpts,
    series: [{
      upColor: COLORS[0],
      negativeColor: COLORS[1],
      data,
      dataLabels: {
        enabled: true,
        format: [
          measure.includes('$') ? '$' : '',
          '{y:,.',
          decimal,
          'f}',
          measure.replace('$', ''),
        ].join('')
      }
    }]
  }

  return <>
    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
  </>
}
export default WaterfallChart;
