import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import {isMobile} from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import Footer from "./import_values/Footer";
import { setLargeScalePanelOpen } from "../../store/sidebar/actions";
import ImportFrom from './import_values/ImportFrom';
import SourceScenario from './import_values/SourceScenario';
import { importValues } from "../../store/forecast_simulator_scenario/actions";
import { isBlank, isPresent } from "../../helpers/common";
import { showAlert } from "../../store/alerts/actions";
import { cmuScopes, DEFAULT_SCOPES, selectedCmuCombinations } from "../helpers/scopes_helpers";
import { successAlert } from "../helpers/helpers";

const ImportValuesPanel = ({
                             sidebar,
                             forecast_simulator_scenario,
                             config,
                             forecastScenario,
                             openSideBar,
                             showAlert,
                             importValues
                           }) => {
  if(!forecast_simulator_scenario.scenario_loaded) return null;

  const [submit, setSubmit] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const { scopes, updateScope } = cmuScopes(config, forecastScenario, {}, DEFAULT_SCOPES.any_simulated)

  const updateAction = () => {
    setSubmit(true);
    const cmusCombinations = selectedCmuCombinations(scopes);
    const cmus = cmusCombinations.some(cmus => isPresent(cmus)) ? cmusCombinations : forecast_simulator_scenario.scenario.cmus;
    importValues(selectedScenario.value, cmus, () => {});
  }

  useEffect(() => {
    if(submit && isBlank(forecast_simulator_scenario.import_values)) {
      successAlert(showAlert);
      setSubmit(false);
    }
  }, [forecast_simulator_scenario.import_values])

  return <SlidingPanel
    type={'left'}
    isOpen={sidebar.importValuesSidebar}
    panelContainerClassName={`sidebar-panel-wrapper large-scale-panel-wrapper left-side-panel-position`}
    panelClassName={`${isMobile ? '' : 'd-flex flex-column'} sidebar-panel-wrapper-content large-scale-input-panel-content h-100`}
    size={50}>
    <PanelHeader headerName={'Import values'} openSideBar={openSideBar} preview={true}/>
    <div className={'px-3'}>
      <SourceScenario forecast_simulator_scenario={forecast_simulator_scenario}
                      selectedScenario={selectedScenario}
                      setSelectedScenario={setSelectedScenario}/>
    </div>
    <ImportFrom config={config} scopes={scopes} updateScope={updateScope} scenarioCmus={forecast_simulator_scenario.cmus} />
    <Footer updateAction={updateAction} disabled={isBlank(selectedScenario) || submit} />
  </SlidingPanel>
}
const mapStateToProps = ({ sidebar, forecast_simulator_scenario }) =>
  ({ sidebar, forecast_simulator_scenario });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setLargeScalePanelOpen(options));
  },
  showAlert: (data) => dispatch(showAlert(data)),
  importValues: (scenario_id, cmus, callback) => dispatch(importValues(scenario_id, cmus, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(ImportValuesPanel);
