import { isPresent } from "../helpers/common";

const UNSTARTED_TREE_FLOWS = window.TreeLayoutConstants.decision.unstarted_tree_flows;

export const isUnstartedDecision = ({ flow_enum }) => UNSTARTED_TREE_FLOWS.includes(flow_enum)
export const isDraftDecision = (decision) => isPresent(decision?.pinned)

export const DECISION_STATES_DATA = {
  unstarted: { state: 'unstarted',  icon_classes: 'fa-duotone fa-circle square-24', color: 'text-light-gray' },
  in_flight: { state: 'in_flight', icon_classes: 'fa-solid fa-circle-dot square-24', color: 'text-warning' },
  completed: { state: 'completed', icon_classes: 'fa-solid fa-circle-check square-24', color: 'text-success' }
}

export default class DecisionState {
  constructor(decisionObject) {
    this.decisionObject = decisionObject;
  }

  get state() {
    if(this.isCompleted) return DECISION_STATES_DATA.completed.state;
    if(this.isInFlight) return DECISION_STATES_DATA.in_flight.state;
    if(this.isUnstarted) return DECISION_STATES_DATA.unstarted.state;
  }

  get isInFlight() {
    return !isDraftDecision(this.decisionObject.decision) && !this.decisionObject.isRecordedOrShowAsCompleted;
  }

  get isUnstarted() {
    return isUnstartedDecision(this.decisionObject.decision) || isDraftDecision(this.decisionObject.decision);
  }

  get isCompleted() {
    return this.decisionObject.isRecordedOrShowAsCompleted;
  }
}
