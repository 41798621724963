import React from 'react';
import { connect } from "react-redux";
import {openModal, updateDecision} from "../../../store/modals/actions";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import { IconBtnDropdown } from "../../../common/dropdowns";
import Alerts from "../../../../js/alerts";
import {openDecisionPollModal} from "../../modals/DecisionPollModal";
import {setPollPanelOpen} from "../../../store/sidebar/actions";
import {collaboratorCanEditTree, isDecider} from "../../../helpers/user_helpers";
import {saveSideBarData} from "../../../store/sidebar/common_actions";
import {updateDecisionData} from "../../../store/decision/common_actions";

const removePoll = (updateDecision, setPollPanelOpen, saveSideBarData, updateDecisionData) => {
  Alerts.warning({
    title: `Are you sure you would like to delete the decision poll?`,
  }).then(confirmed => {
    if (confirmed) {
      const callback = (success, decision) => {
        if (success){
          saveSideBarData({ decision: {...decision }})
          updateDecisionData({ decision: {...decision }})
          setTimeout(() => setPollPanelOpen(false), [50])
        }
      }
      updateDecision({template: {reset_votes: true}}, callback)
    }
  }, () => {})
}

const PollMoreActionsSection = ({decision, openModal, updateDecision, setPollPanelOpen, className = '',
                                  current_user, saveSideBarData, updateDecisionData
                                }) => {
  if (!isDecider(current_user) && !collaboratorCanEditTree(current_user, decision)) return null;

  const editPoll = () => {
    openDecisionPollModal({ openModal, type: 'DecisionPollModal', decision });
  }

  return <div className={className}>
    <IconBtnDropdown id={'PollMoreActionsSection'}>
      <BaseDropdownBtn onSelect={editPoll} >
        Edit decision poll
      </BaseDropdownBtn>
      <BaseDropdownBtn onSelect={() => removePoll(updateDecision, setPollPanelOpen, saveSideBarData, updateDecisionData)} bsPrefix="text-danger">
        Delete decision poll
      </BaseDropdownBtn>
    </IconBtnDropdown>
  </div>
}
const mapStateToProps = ({ decision, current_user }) => ({ decision, current_user });

export default connect(mapStateToProps, {
  openModal, updateDecision, setPollPanelOpen,
  saveSideBarData, updateDecisionData
})(PollMoreActionsSection);
