import { isBlank, isPresent } from "../../helpers/common";
import {CMUS_GROUP_KEY_SEPARATOR, GROUP_COL_ID_SUFFIX} from "./ag_grid_vars";
import { getScenarioFiltersJson, getTableOrderFromCookies, setScenarioFiltersToCookies } from "./ag_grid_cookies";

export const DEFAULT_TOTAL_PAGES = 1;
const MAX_CMUS_GROUP_SIZE_PER_PAGE = 200;
const MIN_PAGINATION_PAGE_SIZE = 1;

const calcPaginationPageSize = (filteredCmusTable) => {
  const values = Object.values(filteredCmusTable);
  const totalCmusSize = values.reduce((acc, cmusList) => acc + cmusList.length, 0);
  const maxCmusGroupSize = Math.max(...values.map(cmusList => cmusList.length));
  const hasLargeGroup = values.some(cmusList => cmusList.length > MAX_CMUS_GROUP_SIZE_PER_PAGE);

  if (hasLargeGroup) return MIN_PAGINATION_PAGE_SIZE;

  return Math.ceil(totalCmusSize / maxCmusGroupSize);
};

const applyRootCmuFilters = (filterModel, forecastScenario, config) => {
  const firstCmuColumnName = `${config.cmuColumns[0].name}-${GROUP_COL_ID_SUFFIX}`;
  const filterKeys = Object.keys(filterModel).filter(key => key === firstCmuColumnName);
  const cmuColumnsMap = config.cmuColumns.reduce((acc, column) => {
    acc[column.name] = column;
    return acc;
  }, {});

  return Object.fromEntries(
    Object.entries(forecastScenario.cmusTable).reduce((acc, [index, cmusList]) => {
      const filteredCmusList = cmusList.filter(cmuGroupKey => {
        return !filterKeys.some(filterKey => {
          const columnName = filterKey.split('-')[0];
          if (!cmuColumnsMap[columnName]) return false;

          const filteredValues = filterModel[filterKey].values;
          const cmuColumn = cmuColumnsMap[columnName];
          const filteredValuesIds = filteredValues.map(value => Object.keys(cmuColumn.values).find(key => cmuColumn.values[key] === value));
          return !filteredValuesIds.some(cmu => cmuGroupKey.includes(cmu));
        });
      });
      if (isPresent(filteredCmusList)) acc.push([index, filteredCmusList]);
      return acc;
    }, [])
  );
};

export const applyDescSorting = (filteredCmusTable) => {
  return Object.fromEntries(Object.entries(filteredCmusTable).reverse().map(([index, cmusList], order) => [`${index}-${order}`, cmusList]));
};

const resetEmptyFiltersOnLoad = (forecastScenario, filterModel) => {
  const adjustedModel = Object.fromEntries(Object.entries(filterModel).map(([key, value]) => {
    if (isPresent(value.values)) {
      return [key, value];
    }
  }).filter(isPresent));
  setScenarioFiltersToCookies(forecastScenario, adjustedModel);
  return adjustedModel;
};

export const setupShownCmuGroups = (forecastScenario, page, config, loading) => {
  let filterModel = getScenarioFiltersJson(forecastScenario);
  if(loading && filterModel) {
    filterModel = resetEmptyFiltersOnLoad(forecastScenario, filterModel);
  }
  const order = getTableOrderFromCookies(forecastScenario) || 'asc';
  const filteredCmusTable = applyRootCmuFilters(filterModel, forecastScenario, config);
  const sortedFilteredCmusTable = order === 'asc' ? filteredCmusTable : applyDescSorting(filteredCmusTable);
  const sortedFilteredCmusTableKeys = Object.keys(sortedFilteredCmusTable);
  const paginationPageSize = calcPaginationPageSize(filteredCmusTable);
  const cmusGroupKeys = sortedFilteredCmusTableKeys.slice(page * paginationPageSize, (page + 1) * paginationPageSize);
  const cmusGroupValues = cmusGroupKeys.flatMap(key => sortedFilteredCmusTable[key]);
  if (isBlank(cmusGroupValues)) return { newCurrentCmuGroups: [], newTotalPages: DEFAULT_TOTAL_PAGES };

  const cmus = cmusGroupValues.map(groupKey => groupKey.split(CMUS_GROUP_KEY_SEPARATOR).slice(0, -1).map(i => parseInt(i)));
  return { newCurrentCmuGroups: cmus, newTotalPages: Math.ceil(sortedFilteredCmusTableKeys.length / paginationPageSize) };
};
