import React, { useState, useRef } from 'react';
import { CheckForm } from "../../../tree_wizard/helpers/DecisionFlowRow";

const AllowManageCollaboratorsInput = ({ decision, onChangeSettings }) => {
  const [submit, setSubmit] = useState(true);
  const { allow_collaborators_edit_collaborators, read_only } = decision;
  const checkboxRef = useRef(null);

  return(
    <CheckForm
      label={'Allow collaborators to add or remove collaborators'}
      disabled={!submit || read_only}
      type={'checkbox'}
      className='mb-0 mt-2'
      checked={allow_collaborators_edit_collaborators === true}
      onClick={() => onChangeSettings({ allow_collaborators_edit_collaborators: !allow_collaborators_edit_collaborators }, checkboxRef, setSubmit)}
      ref={checkboxRef}
    />
  )
};

export default AllowManageCollaboratorsInput;
