const BRAND_NODE_TYPE = window.TreeLayoutConstants.dsight.brand_node_type;
const ADV_NODE_TYPE = 'advertising';
const ADV_TYPE_NODE_TYPE = 'advertising_type';

export const brandDimension = (goal) =>
  goal.dimensions.find(({ node_type }) => node_type === BRAND_NODE_TYPE)

export const advertisingDimension = (goal) =>
  goal.dimensions.find(({ node_type }) => node_type === ADV_NODE_TYPE)

export const advertisingTypeDimension = (goal) =>
  goal.dimensions.find(({ node_type }) => node_type === ADV_TYPE_NODE_TYPE)
