import React from 'react';
import { connect } from "react-redux";
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import { isMobile } from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import ContextSection from "../../tree_view/side_panel/sections/ContextSection";
import DeleteSection from "./DeleteSection";
import {isBlank} from "../../helpers/common";

export const DecisionDetailsPanel = ({ sidebar, decision, openSideBar }) =>
  <SlidingPanel
    type="left"
    isOpen={decision.loaded && sidebar.decisionDetailsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper decision-panel details-panel-width left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Details'} openSideBar={openSideBar} preview={true} />
    {isBlank(decision.collaboration_context) ? null : <ContextSection isLegacy={true}/>}
    <DeleteSection />
  </SlidingPanel>
const mapStateToProps = ({ sidebar, decision }) => ({ sidebar, decision });
export default connect(mapStateToProps, { openSideBar: setDecisionSidebarOpen })(DecisionDetailsPanel);
