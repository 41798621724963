import { useEffect, useState, useMemo } from "react";
import { isFuturePeriod } from "../../models/forecast/ForecastScenario";
import { isBlank, isPresent } from "../../helpers/common";
import { getContextMenuItems, showContextMenu } from "./ag_grid_context_menu";
import CustomLoadingOverlay, { showOverlayWithMessage, MESSAGES, hideOverlay } from "./custom_loading_overlay";
import { preparedColDefs, totalAggFunc } from "./ag_grid_col_defs";
import { styledPeriodColumn, styledFactsColumn } from "./ag_grid_cell_style";
import { useApplyLargeScaleInputEffect } from "./ag_grid_large_scale_input_helpers";
import { useRunModelEffect, prepareDataForRunModel } from "./ag_grid_run_model";
import { useImportValuesEffect } from "./ag_grid_import_values";
import { extractDriverName, onCellValueChanged, rowCellIdKey } from "./common";
import { ROW_DRIVER_ID_KEY, GROUP_COL_ID_SUFFIX } from "./ag_grid_vars";
import {
  saveCollapsedGroups,
  restoreForecastCookies, saveColumnState, saveTableOrderToCookies, getTableOrderFromCookies
} from "./ag_grid_cookies";
import { onBodyScrollEnd, onBodyScroll, onHorizontalScrollBody } from "./ag_grid_scroll";
import { saveFilters } from "./ag_grid_cookies";

const getRowStyle = (params, forecastScenario) => {
  if(isValueSalesRow(params, forecastScenario)) {
    return { border: 'none' }
  }
  if (params.node.rowIndex % 2 === 0) {
    return { background: '#FFFFFF' };
  }
}

const isValueSalesRow = (params, forecastScenario) => {
  return params.node.key === forecastScenario.valueSalesDriverName
}

const applyEditedCells = (rows, editedCells, newTimeScale) => {
  return rows.map((row) => {
    const editedCellsData = editedCells.filter(cellData => cellData.nodeId === row[ROW_DRIVER_ID_KEY] && newTimeScale === cellData.timeScale);
    if(isPresent(editedCellsData)) {
      editedCellsData.forEach(editedCellData => {
        row[editedCellData.field] = editedCellData.value;
      })
    }
    return row;
  })
}

const calcUpdatedCells = (runModelCells) => {
  return runModelCells.filter(cellData =>
    isBlank(cellData.request_run_model_at) && isPresent(cellData.run_model_at) //&& cellData.value !== cellData.default_value
  );
}

const updateScheduledCells = (timeScale, editedCells, onCellValueChangedWrapper) => {
  const cellsToUpdate = editedCells.filter(cellData => timeScale === cellData.timeScale && cellData.need_recalculation).map(cellData => {
    cellData.need_recalculation = false
    return cellData;
  });
  if(isBlank(cellsToUpdate)) return;

  onCellValueChangedWrapper([], () => {}, '', cellsToUpdate);
}

const anyEditedCells = (editedCells) => editedCells.some(cellData => cellData.edited);

const onSortChanged = (event, forecastScenario, setNeedUpdate) => {
  const prevOrder = getTableOrderFromCookies(forecastScenario);
  const colId = `${forecastScenario.config.cmuColumns[0].name}-${GROUP_COL_ID_SUFFIX}`;
  const newOrder = event.columns.find(column => column.colId === colId)?.sort || 'asc';
  if(prevOrder !== newOrder) {
    saveTableOrderToCookies(newOrder, forecastScenario);
    setNeedUpdate(true);
  }
};

const afterRenderActions = ({
                              gridRef,
                              forecastScenario,
                              forecast_simulator_scenario,
                              updateScenarioData,
                              editedCells,
                              onCellValueChangedWrapper,
                              expandedGroupIds,
                              expandedGroups,
                              setScrollInProcess,
                              forecastBenchmarkScenario,
                              updateScenario
                           }) => {
  setTimeout(() => {
    restoreForecastCookies(gridRef.current, forecastScenario, forecast_simulator_scenario, updateScenarioData);
    updateScheduledCells(forecastScenario.timeScale, editedCells, onCellValueChangedWrapper);
    onHorizontalScrollBody({ gridRef, forecastScenario, expandedGroupIds, expandedGroups, setScrollInProcess, forecastBenchmarkScenario, editedCells, updateScenario });
  }, 100);
};

export const agGridInit = ({
                             forecast_simulator_scenario, setRunModelActive, gridRef,
                             currentCmuGroups,
                             config, forecastScenario, forecastBenchmarkScenario, timeScale,
                             updateScenario, setLargeScalePanelOpen, updateScenarioData, runModel,
                             setNeedUpdate
                           }) => {
  const [gridReady, setGridReady] = useState(false);
  const [editedCells, setEditedCells] = useState(forecastScenario.actualEditedCells || []);
  const [runModelCells, setRunModelCells] = useState(forecastScenario.actualRunModelCells);
  const colDefsOpts = { forecastBenchmarkScenario, forecastScenario, config, timeScale };
  const [colDefs, setColDefs] = useState(preparedColDefs({...colDefsOpts}));
  const [rowData, setRowData] = useState([]);
  const runModelCellsToHighlight = useMemo(() => runModelCells.filter(cellData => isPresent(cellData.request_run_model_at)), [runModelCells]);
  const editedCellsIds = useMemo(() => editedCells.map(cellData => cellData.id), [editedCells]);
  const runModelRowsIds = useMemo(() => runModelCells.map(cellData => cellData.nodeId), [runModelCells]);
  const editedCellsRowIds = useMemo(() => editedCells.map(cellData => cellData.nodeId), [editedCells]);
  const updatedCells = useMemo(() => calcUpdatedCells(runModelCells), [runModelCells]);
  const openedGroups = useMemo(() => forecastScenario.openedGroups, [forecastScenario.openedGroups]);
  const expandedGroups = useMemo(() => openedGroups.filter(g => g.expanded), [openedGroups]);
  const expandedGroupIds = useMemo(() => expandedGroups.map(g => g.id), [expandedGroups]);
  const [scrollInProcess, setScrollInProcess] = useState(false);

  useEffect(() => {
    if(gridReady) {
      showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.loading_rows);
      setTimeout(() => {
        const localEditedCells = forecastScenario.actualEditedCells || [];
        const rows = forecastScenario.preparedRowsForTable(currentCmuGroups);
        const allRows = [...rows, ...forecastScenario.addedComparisonRows(rows)];
        setRowData(applyEditedCells(allRows, localEditedCells, forecastScenario.timeScale));
        hideOverlay(gridRef.current.api);
        afterRenderActions({  gridRef, forecastScenario, forecast_simulator_scenario, updateScenarioData, editedCells, onCellValueChangedWrapper, expandedGroupIds, expandedGroups, setScrollInProcess, forecastBenchmarkScenario, updateScenario });
      }, 500);
    }
  }, [currentCmuGroups]);

  useEffect(() => {
    if(gridReady) {
      const locEditedCells = forecastScenario.actualEditedCells || [];
      const locRunModelCells = forecastScenario.actualRunModelCells;
      setEditedCells(locEditedCells);
      setRunModelCells(locRunModelCells);
      setRunModelActive(anyEditedCells(locEditedCells));
    }
  }, [forecastScenario.updateData])

  useEffect(() => {
    if(gridReady && isPresent(forecastScenario.viewOptions.timeScale)) {
      forecastScenario.setTimeScale(forecastScenario.viewOptions.timeScale);
      const localEditedCells = forecastScenario.actualEditedCells || [];
      setColDefs(preparedColDefs({ ...colDefsOpts, timeScale: forecastScenario.timeScale }));
      const rows = forecastScenario.preparedRowsForTable(currentCmuGroups);
      const allRows = [...rows, ...forecastScenario.addedComparisonRows(rows)];
      setRowData(applyEditedCells(allRows, localEditedCells, forecastScenario.timeScale));
      afterRenderActions({  gridRef, forecastScenario, forecast_simulator_scenario, updateScenarioData, editedCells, onCellValueChangedWrapper, expandedGroupIds, expandedGroups, setScrollInProcess, forecastBenchmarkScenario, updateScenario });
    }
  }, [forecastScenario.viewOptions.timeScale, forecastScenario.viewOptions.from, forecastScenario.viewOptions.to])

  useRunModelEffect({
    gridRef,
    gridReady,
    editedCells,
    forecast_simulator_scenario,
    forecastScenario,
    forecastBenchmarkScenario,
    updateScenario,
    setRunModelActive,
    runModelCells,
    updateScenarioData,
    runModelRowsIds,
    editedCellsRowIds,
  })
  useApplyLargeScaleInputEffect({
    gridRef,
    forecast_simulator_scenario,
    editedCells,
    updateScenario,
    forecastScenario,
    forecastBenchmarkScenario,
    runModelCells,
    updateScenarioData
  });

  useImportValuesEffect({
    gridRef,
    forecast_simulator_scenario,
    editedCells,
    updateScenario,
    forecastScenario,
    runModelCells,
    updateScenarioData
  });

  const onResetCells = (rowNode, list) => {
    const updateData = {}
    const updatedList = list.map(params => {
      const editedCell = editedCells.find(editedCell => rowCellIdKey(params) === editedCell.id)
      if(editedCell && editedCell.hasOwnProperty('default_value') && editedCell.default_value !== editedCell.value) {
        params.newValue = editedCell.default_value;
        params.edited = editedCell.run_model_is_run;
        updateData[params.colDef.field] = editedCell.default_value
        return params;
      }
    }).filter(isPresent);
    onCellValueChangedWrapper(updatedList,() => {
      gridRef.current.api.applyTransaction({ update: [{ ...rowNode.data, ...updateData }] });
    }, 'reset');
  }

  const onCellValueChangedWrapper = (list, callback = () => {}, action = '', newEditedCells = []) => {
    showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
    setTimeout(() => {
      onCellValueChanged(forecastScenario, newEditedCells, list, gridRef, editedCells, updateScenario, forecastScenario.timeScale, runModelCells, callback, action)
    }, 0)
  };

  const onGridReady = () => {
    showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.loading_rows);
    setTimeout(() => {
      try {
        setColDefs(preparedColDefs({ ...colDefsOpts, timeScale: forecastScenario.timeScale }));
        const rows = forecastScenario.preparedRowsForTable(currentCmuGroups);
        const allRows = [...rows, ...forecastScenario.addedComparisonRows(rows)];
        setRowData(applyEditedCells(allRows, editedCells, timeScale));
        setGridReady(true);
        hideOverlay(gridRef.current.api);
      } catch (error) {
        console.error('An error occurred during preparing rows for table:', error);
      }
    }, 700);
  };
  const openLargeScalePanel = (driverId) => setLargeScalePanelOpen(true, driverId);
  const onRunModel = () => {
    setRunModelActive(false);
    showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.updating_scenario);
    const { driversData, cmusList } = prepareDataForRunModel(editedCells, forecastScenario);
    runModel(forecast_simulator_scenario.scenario_id, { drivers: driversData, cmus: cmusList });
  }

  // AG Grid settings functions
  const getRowId = useMemo(() => ((params) => params.data[ROW_DRIVER_ID_KEY]), []);
  const aggFuncs = useMemo(() =>
    ({ 'totalAggFunc': (params) => totalAggFunc(params, forecastScenario) }), [forecastScenario]);
  const isGroupOpenByDefault = params => {
    return params.field !== 'Facts' || forecastScenario.openedGroupsIds.includes(params.rowNode.id)
  }
  const defaultColDef = useMemo(() => ({
    enableCellChangeFlash: true,
    menuTabs: ["filterMenuTab", "generalMenuTab"],
  }), []);
  // Define column types
  const columnTypes = useMemo(() => ({
    styledPeriodColumn: {
      cellStyle: (params) => styledPeriodColumn(forecastScenario, params, editedCells, editedCellsIds, updatedCells, config, timeScale, runModelCellsToHighlight)
    },
    styledFactsColumn: {
      cellStyle: (params) => styledFactsColumn(params, editedCellsRowIds, config, timeScale)
    },
    editableColumn: {
      onCellValueChanged: (params) => {
        if(isPresent(params.newValue) && isPresent(params.oldValue)) {
          onCellValueChangedWrapper([params])
        } else {
          params.api.undoCellEditing();
        }
      }
    },
  }), [forecastScenario, runModelCells, editedCells, editedCellsIds, runModelCellsToHighlight, updatedCells, editedCells, config, timeScale]);

  return {
    onRunModel,
    rowData,
    pagination: true,
    suppressPaginationPanel: true,
    paginationPageSizeSelector: false,
    rowStyle: { background: '#FBFCFE' },
    columnDefs: colDefs,
    rowGroupPanelShow: 'never',
    rowGroupPanelSuppressSort: true,
    groupSuppressBlankHeader: true,
    groupAllowUnbalanced: true,
    reactiveCustomComponents: true,
    loadingOverlayComponent: CustomLoadingOverlay,
    undoRedoCellEditing: true,
    columnMenu: 'legacy',
    undoRedoCellEditingLimit: 1,
    autoSizeStrategy: {
      type: "fitCellContents",
    },
    defaultColDef,
    getRowStyle: (params) => getRowStyle(params, forecastScenario),
    getRowId,
    aggFuncs,
    isGroupOpenByDefault,
    groupDisplayType: 'custom',
    suppressAggFuncInHeader: true,
    onGridReady,
    columnTypes,
    getContextMenuItems: (params) => {
      if(isBlank(params.value)) return [];

      const periodName = params.column.colDef.field;
      const periodId = params.column.colDef.colId;
      if(showContextMenu(params, config, timeScale, forecastScenario, periodName)) {
        const driverHeader = extractDriverName(params.node.data.Facts);
        const editable = config.isEditableDriver(timeScale, driverHeader) && isFuturePeriod(forecastScenario, periodId);
        return getContextMenuItems(gridRef, params, editable, colDefs, onCellValueChangedWrapper, onResetCells, forecastScenario.allForecastedEditablePeriods, openLargeScalePanel);
      }
      return [];
    },
    onFirstDataRendered: (event) => {
      setRunModelActive(anyEditedCells(editedCells));
      afterRenderActions({ gridRef, forecastScenario, forecast_simulator_scenario, updateScenarioData, editedCells, onCellValueChangedWrapper, expandedGroupIds, expandedGroups, setScrollInProcess, forecastBenchmarkScenario, updateScenario });
      setTimeout(() => event.api.autoSizeAllColumns(), 100);
    },
    onFilterChanged: (event) => saveFilters(event, forecastScenario, forecast_simulator_scenario, updateScenarioData, setNeedUpdate),
    onSortChanged: (event) => onSortChanged(event, forecastScenario, setNeedUpdate),
    onRowGroupOpened: (event) => saveCollapsedGroups(event, forecastScenario),
    onColumnPinned: (event) => saveColumnState(event, forecastScenario),
    getRowHeight: function(params) {
      // NOTE: tricky way to hide extra Value Sales row(we actually need it to display aggregated Value Sales above)
      if (isValueSalesRow(params, forecastScenario)) {
        return 0;
      } else {
        return params.node.rowHeight;
      }
    },
    onBodyScroll: (event) => onBodyScroll(),
    onBodyScrollEnd: (event) =>
      onBodyScrollEnd({
        event,
        gridReady,
        gridRef,
        config,
        forecastScenario,
        forecastBenchmarkScenario,
        editedCells,
        expandedGroupIds,
        expandedGroups,
        updateScenarioData,
        updateScenario,
        scrollInProcess,
        setScrollInProcess
      }
    )
  };
};
