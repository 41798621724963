import React, { useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { updateDriver } from "../../../store/sidebar/actions";
import Decision from "../../../models/decision";
import { Accordion, Card } from 'react-bootstrap'
import { JoditTextEditor } from '../../../common/JoditTextEditor';
import { DriverChoiceRating, MAX_SCALE, MIN_SCALE } from "../../../models/DriverChoiceRating";
import debounce from 'lodash.debounce';
import Driver from "../../../models/driver";
import { RatingSlider } from "../helpers/CustomSlider"
import Button from 'react-bootstrap/Button';
import { DEFAULT_RATING_SCALES_DATA, RATING_SCALES_DATA } from "../../../helpers/drivers_helpers";
import CustomHeaderToggle from "../CustomHeaderToggle"
import {toggleClickAction, openAllActionUseEffect, setAccordionDropdownToggleState} from "../helpers/accordion_helpers";
import {CustomRating} from "../../modals/DriverShowModal";

export const CustomToggle = ({ obj, eventKey, children, startScale, endScale, openIndexes, setOpenIndexes, isYesOrNo }) => {
  const [openState, setOpenState] = useState(false);

  setAccordionDropdownToggleState(openIndexes, eventKey, {setOpenState})

  return (
    <div onClick={() => toggleClickAction(openIndexes, eventKey, {setOpenIndexes})}
         className="d-flex fw-bolder border-0 align-items-baseline">
      <span className={`button me-1 ${obj.isAnswered ? 'answered' : 'non-answered'}`} />
      <div className="text-ellipsis me-1">{children}</div>
      <div className={`dropdown-toggle ms-auto ${openState ? 'rotate-after-180' : ''}`}>
        {obj.isNA ? '' :
          <CustomRating driveChoicesRating={obj} startScale={startScale} endScale={endScale} isYesOrNo={isYesOrNo} />
        }
      </div>
    </div>
  );
};

const saveDriverChoiceCallback = (driverObj, rateChoiceData, rating, evaluation, updateDriver) => {
  const _updateDriver = debounce(() => {
    updateDriver({ slug: driverObj.driver.slug, driver_choice: { choice_slug: rateChoiceData.choice_slug, rating, evaluation }, draft: driverObj.driver.draft })
  }, 500);

  useEffect(() => {
    if (rateChoiceData.rating !== rating || evaluation !== rateChoiceData.evaluation) {
      _updateDriver();
    }
    return () => {
      _updateDriver.cancel();
    }
  }, [rating, evaluation]);
}

export const ChoiceRow = ({
                     rateChoiceData, driverObj, updateDriver, openIndexes, setOpenIndexes,
                     saveDriverChoiceState = () => {}, isResetInitData = false
                   }) => {
  const [rating, setRating] = useState(isResetInitData ? null : rateChoiceData.rating)
  const [evaluation, setEvaluation] = useState(isResetInitData ? '' : rateChoiceData.evaluation)
  const [yesValue, setYesValue] = useState(rating === MAX_SCALE)
  const [noValue, setNoValue] = useState(rating === MIN_SCALE)
  const startScale = RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.start_scale || MIN_SCALE
  const endScale = RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.end_scale || MAX_SCALE
  const isYesOrNo = RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.yes_or_no;
  const ratingLabels = driverObj.driver?.rating_labels || {}
  const obj = new DriverChoiceRating({ rating, evaluation })

  const sliderTitle = driverObj.withRatingScale ? RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.title : DEFAULT_RATING_SCALES_DATA.title;

  isResetInitData ?
    saveDriverChoiceState(rateChoiceData, rating, evaluation) :
    saveDriverChoiceCallback(driverObj, rateChoiceData, rating, evaluation, updateDriver)

  return <div className='mb-2'>
    <Accordion>
      <Card className="border-0">
        <Card.Header className="bg-white border-0 pointer p-0">
          <CustomToggle eventKey={rateChoiceData.choice_slug} {...{obj, startScale, endScale, openIndexes, setOpenIndexes, isYesOrNo}}>
            {rateChoiceData.choiceDescription}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={rateChoiceData.choice_slug} className={`${openIndexes.includes(rateChoiceData.choice_slug) ? 'show' : ''}`}>
          <Card.Body className="p-0">
            {
              driverObj.isSubDriver ? null :
                isYesOrNo ?
                  <div className="row mt-2">
                    <div className="col pe-1">
                      <Button onClick={() => {
                        if (noValue) setNoValue(!noValue);
                        setRating(yesValue ? null : MAX_SCALE)
                        setYesValue(!yesValue);
                      }} bsPrefix={`btn ${yesValue ? 'btn-primary' : 'btn-secondary'} w-100 list-inline-item`}>
                        Yes
                      </Button>
                    </div>
                    <div className="col ps-1">
                      <Button onClick={() => {
                        if (yesValue) setYesValue(!yesValue);
                        setRating(noValue ? null : MIN_SCALE)
                        setNoValue(!noValue);
                      }} bsPrefix={`btn ${noValue ? 'btn-primary' : 'btn-secondary'} w-100 list-inline-item`}>
                        No
                      </Button>
                    </div>
                  </div> :
                  <RatingSlider {...{
                    sliderTitle: <span className="text-gray">{sliderTitle}</span>,
                    isSubDriver: driverObj.isSubDriver,
                    showResetButton: !obj.isNA,
                    rating,
                    setRating,
                    startScale,
                    endScale,
                    ratingLabels
                  }} />
            }
            <div className="mt-2">
              <JoditTextEditor className="jodit-container-default"
                               value={evaluation}
                               placeholder="Describe the evaluation or analysis for this choice"
                               setValue={setEvaluation}
                               hasOnChangeAction={true}
                               autofocus={false} />
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>
}

const RateDriverChoicesInput = ({ decision, drivers, driverData, ...opts }) => {
  const decisionObject = useMemo(() => new Decision(decision, drivers), [decision]);
  const [openAll, setOpenAll] = useState(false);
  const [openIndexes, setOpenIndexes] = useState([]);
  const driverObj = useMemo(() => new Driver(driverData.driver, [], {}, decisionObject, true), [driverData, decisionObject]);
  const allRateChoicesSlugs = useMemo(() => driverObj.sortedDriverChoicesRatings.map((rateChoiceData) => rateChoiceData.choice_slug), [driverObj]);

  openAllActionUseEffect(openAll, openIndexes, allRateChoicesSlugs, {setOpenIndexes, setOpenAll});

  if (decisionObject.isRateAndCompareDriverChoicesHidden) return null;

  return <div className="mb-3 px-3">
    <CustomHeaderToggle {...{openAll, setOpenAll}}>
      <h3>{ driverObj.question }</h3>
    </CustomHeaderToggle>
    {
      driverObj.sortedDriverChoicesRatings.map((rateChoiceData, index) =>
        <ChoiceRow key={`choice-${rateChoiceData.choice_slug}-${index}`} {...{rateChoiceData, driverObj, openIndexes, setOpenIndexes}} {...opts} />)
    }
  </div>
}

const mapStateToProps = ({ decision, tree }) => ({ decision, drivers: tree.drivers })
export default connect(mapStateToProps, { updateDriver })(RateDriverChoicesInput);
