import React, { useState, useEffect } from "react";
import { connect} from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooterP0 } from "../../common/modals";
import CharCounter from "../../common/CharCounter";
import { DISPLAY_NAME_LIMIT } from "../../models/forecast/ForecastScenario";
import { isBlank } from "../../helpers/common";
import { createForecastSimulatorRecord } from "../../store/homepage/actions";
import { updateScenarioData } from "../../store/forecast_simulator_scenario/actions";

export const showSaveAsNewModalModal = (modal) => modal.type === "SaveAsNewModal" && modal.shown
export const openSaveAsNewModalModal = ({ openModal, ...opts }) => openModal({ ...opts, type: 'SaveAsNewModal' })

const SaveAsNewModal = ({
                         forecast_simulator_scenario, show, key,
                         onClose, createForecastSimulatorRecord, updateScenarioData
                       }) => {
  if(!forecast_simulator_scenario.loaded) return null;

  const history = useHistory()
  const focusElement = React.useRef(null);
  useEffect(() => {
    if (show && focusElement.current) focusElement.current.focus()
  }, [show, focusElement])

  const [display_name, setDisplayName] = useState(`${forecast_simulator_scenario.scenario?.display_name} (copy)`);
  const [showChar, setShowChar] = useState(false);
  const [errors, setErrors] = useState({})
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (show) setDisplayName(`${forecast_simulator_scenario.scenario?.display_name} (copy)`)
  }, [forecast_simulator_scenario.scenario, show])

  const onSubmit = () => {
    setSubmit(true);
    updateScenarioData({ ag_grid_aggregated_fact: null });
    createForecastSimulatorRecord(forecast_simulator_scenario.config.data.id, {
      scenario: {
        display_name: display_name.trim(),
        copy_from_id: forecast_simulator_scenario.scenario_id
      },
      tiny: true
    }, (scenario, errors) => {
      setSubmit(false);
      if (scenario) {
        setTimeout(() => {
          history.push(`/forecast_simulator/scenarios/${scenario.id}`)
        }, 100)
        onClose()
      } else {
        setErrors(errors)
      }
    })
  }

  return <Modal backdrop="static" key={key} size="md" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Save as a new scenario</h2>
      <h3>Scenario name</h3>
      <CharCounter show={showChar} field={display_name} limit={DISPLAY_NAME_LIMIT} id={`addBadgePill-${key}`}/>
      <div className="mb-3">
        <input className={`form-control ${errors['display_name'] ? 'is-invalid' : ''}`}
               placeholder="Enter the name of scenario"
               ref={focusElement} value={display_name}
               autoFocus={show} disabled={submit} maxLength={DISPLAY_NAME_LIMIT}
               onChange={(e) => setDisplayName(e.target.value)}
               onBlur={() => setShowChar(false)}
               onFocus={() => setShowChar(true)}
        />
        <span className={`d-block mt-1 ${errors['display_name'] ? 'text-danger' : 'hide-text'}`}>
          {errors['display_name']}
        </span>
      </div>
    </Modal.Body>
    <ModalDoneFooterP0 onClose={onSubmit} disabled={submit || isBlank(display_name?.trim())} />
  </Modal>
}
const mapStateToProps = ({ modal, forecast_simulator_scenario }) => ({ modal, forecast_simulator_scenario });
export default connect(mapStateToProps, { createForecastSimulatorRecord, updateScenarioData })(SaveAsNewModal);
