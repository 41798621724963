import { isBlank, isPresent } from "../helpers/common";
import { calculateRatingScore, RATING_SCALES_DATA } from "../helpers/drivers_helpers";

export const MIN_SCALE = 0;
export const MAX_SCALE = 100;

export const DEFAULT_DRIVER_CHOICE_DATA = { evaluation: '', rating:  null };

export const sortChoicesByRating = (choices, drivers, { key_driver_slug = null, postSort = () => {}, disableRatingSorting = false } = {}) => {
  const weights = choices.map(choice => new ChoiceWeights(choice, drivers));
  const sortWeights = (weightA, weightB) => {
    if (!disableRatingSorting) {
      // sort by rating for specific driver
      if (isPresent(key_driver_slug)) {
        const ratingA = weightA.ratingForDriver(key_driver_slug);
        const ratingB = weightB.ratingForDriver(key_driver_slug);

        if (ratingA.ratingNumber > ratingB.ratingNumber) return -1;
        if (ratingB.ratingNumber > ratingA.ratingNumber) return 1;
      }

      // sort by decision overall rating weight
      if (weightA.weightedRatingScore > weightB.weightedRatingScore) return -1;
      if (weightB.weightedRatingScore > weightA.weightedRatingScore) return 1;
    }
    // default sorting
    return postSort(weightA.choice, weightB.choice) || 0;
  };
  return weights.sort(sortWeights).map(w => w.choice);
};

export class DriverChoiceRating {
  constructor({ rating = DEFAULT_DRIVER_CHOICE_DATA.rating, evaluation = DEFAULT_DRIVER_CHOICE_DATA.evaluation,
                choice = null, choice_slug = null, driver = null
              }) {
    this.rating = rating;
    this.evaluation = evaluation;
    this.choice = choice;
    this.driver = driver;
    this.choice_slug = choice_slug;
  }

  get isNA() {
    return this.rating === null;
  }

  get ratingNumber() {
    return this.isNA ? 0 : parseInt(this.rating)
  }

  get isAnswered() {
    return !this.isNA || this.hasEvaluation;
  }

  get hasEvaluation() {
    return isPresent(this.evaluation);
  }

  get choiceDescription() {
    return this.choice?.description;
  }

  get driverSlug() {
    return this.driver?.slug;
  }

  get driverQuestion() {
    return this.driver?.question;
  }
  get driverWeight() {
    return this.driver?.weightPercentage;
  }

  get ratingWeight() {
    if (this.isNA) return 0;
    const startScale = RATING_SCALES_DATA[this.driver.driver.rating_scale]?.start_scale || MIN_SCALE
    const endScale = RATING_SCALES_DATA[this.driver.driver.rating_scale]?.end_scale || MAX_SCALE
    const score = calculateRatingScore(this.rating, startScale, endScale)

    return Math.round(score * this.driver.weightPercentage / 100);
  }
}

export class ChoiceWeights {
  constructor(choice, drivers) {
    this.choice = choice;
    this.drivers = drivers;
    this.ratings = drivers.filter(d => d.isKeyDriver).map(d =>
      d.allDriverChoicesRatings.find(r => r.choice_slug.toString() === choice.slug.toString())
    );
  }

  get weightedRatingScore() {
    return this.ratings.filter(r => !r.isNA)
               .reduce((sum, r) => sum + r.ratingWeight, 0);
  }

  ratingForDriver(driver_slug) {
    if (isBlank(driver_slug)) return null;

    return this.ratings.find(r => r.driver?.slug === driver_slug) || new DriverChoiceRating(DEFAULT_DRIVER_CHOICE_DATA)
  }
}
