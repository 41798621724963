import React, { useRef } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { openModal, transferDataAndCloseModal, updateDecision, updateTemplate } from "../../../../store/modals/actions";
import TemplateEntryModal from "../../../../tree_view/modals/TemplateEntryModal";
import DecisionEntryModal from "../../../../tree_view/modals/DecisionEntryModal";
import MultiEntryModal from "../../../../tree_view/modals/MultiEntryModal";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";
import Decision from "../../../../models/decision";

const modalType = (isTemplate) => isTemplate ? 'TreePanelTemplateEntryModal' : 'TreePanelDecisionEntryModal'
const showDetails = ({ modal, decision, isTemplate }) => modal.type === modalType(isTemplate) && modal.shown && modal.slug === decision.slug;

const DecisionModal = ({ isTemplate, modal, decision, decisionObj, transferDataAndCloseModal, updateTemplate, updateDecision }) =>
  isTemplate ?
    <TemplateEntryModal key={`tree-panel-template-entry-modal-${modal.slug}`}
                        show={showDetails({ modal, decision, isTemplate })}
                        onClose={transferDataAndCloseModal}
                        updateTemplate={updateTemplate}/> :
    (decisionObj.isRecordedOrRecommended || decisionObj.isTreeHistorical) ?
      <DecisionEntryModal key={`tree-panel-decision-entry-modal-${modal.slug}`}
                          show={showDetails({ modal, decision, isTemplate: false })}
                          onClose={transferDataAndCloseModal}
                          updateDecision={updateDecision}/> :
      <MultiEntryModal key={`tree-panel-multi-entry-modal-${modal.slug}`}
                       show={showDetails({ modal, decision, isTemplate: false })}
                       onClose={transferDataAndCloseModal} />

const EditDecision = ({ decision, tree, isTemplate, treeNode, openModal, ...opts }) => {
  const decisionObj = new Decision(decision)
  const ref = useRef();
  const onEditDecision = (e) => {
    e.preventDefault();
    openModal({ decision, drivers: tree.drivers, data_sources: [], slug: treeNode.slug, type: modalType(isTemplate) })
  }
  attachEventEffect(ref, onEditDecision)

  return <>
    <Button className="btn btn-secondary btn-sm btn-sm-round" ref={ref}>
      <i className="fas fa-pencil-alt fa-lg w-100" />
    </Button>
    <DecisionModal {...{ ...opts, isTemplate, decision, decisionObj }} />
  </>
}
const mapStateToProps = ({ modal, decision, template, tree },  { isTemplate }) => ({
  modal, tree, decision: isTemplate ? template : decision
});
export default connect(mapStateToProps, { openModal, transferDataAndCloseModal, updateDecision, updateTemplate })(EditDecision);
