import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TABS } from "./index";
import ScenarioForecastData from "./tabs/ScenarioForecastData";
import ScenarioForecastCharts from "./tabs/ScenarioForecastCharts";
import ScenarioForecastDecomposition from "./tabs/ScenarioForecastDecomposition";
import { generatePromisesCallbacks, isBlank, isPresent } from "../helpers/common";
import { updateScenarioData } from "../store/forecast_simulator_scenario/actions";

const TabContent = ({ isDecompositionTab, activeTab, gridRef, ...opts }) => {
  if(activeTab === TABS.table) return <ScenarioForecastData {...{ gridRef, ...opts }} />
  if(activeTab === TABS.charts) return <ScenarioForecastCharts {...opts} />
  if(isDecompositionTab) return <ScenarioForecastDecomposition {...opts} />
}

const TabManager = ({
                      activeTab,
                      forecastScenario, forecastBenchmarkScenario, timeScale, updateScenarioData,
                      forecast_simulator_scenario,
                      ...opts
                    }) => {
  const controller = new AbortController();
  const setLoading = (loading) => updateScenarioData({ view_loading: loading })
  const [localActiveTab, setLocalActiveTab] = useState(activeTab);
  const isDecompositionTab = localActiveTab === TABS.decomposition;
  const view_options = forecast_simulator_scenario?.scenario?.view_options || {}

  const [simulatedChartDataHashes, setSimulatedChartDataHashes] = useState({})
  const [benchmarkChartDataHashes, setBenchmarkChartDataHashes] = useState({})
  const [filteredSimulationScenarioRows, setFilteredSimulationScenarioRows] = useState(null)
  const [filteredBenchmarkScenarioRows, setFilteredBenchmarkScenarioRows] = useState(null)

  const [simulatedDecompData, setSimulatedDecompData] = useState({})
  const [benchmarkDecompData, setBenchmarkDecompData] = useState({})

  // Make delayed switch to fix active tab highlighting after click
  useEffect(() => {
    const timer = setTimeout(() => {
      setLocalActiveTab(activeTab);
    }, 200);
    return () => clearTimeout(timer);
  }, [activeTab]);

  const { cmusGroups } = forecastScenario.simulationScopesData
  const cmuGroupsCacheKey = cmusGroups.flatMap(row => row).join('-')
  useEffect(() => {
    if (activeTab !== TABS.charts && isPresent(simulatedChartDataHashes)) {
      setSimulatedChartDataHashes({})
      setBenchmarkChartDataHashes({})
    }
  }, [timeScale, view_options.from, view_options.to, cmuGroupsCacheKey])
  useEffect(() => {
    if (!isDecompositionTab && isPresent(simulatedDecompData)) {
      setSimulatedDecompData({})
      setBenchmarkDecompData({})
    }
  }, [cmuGroupsCacheKey])
  useEffect(() => {
    if (!forecast_simulator_scenario.run_model && isPresent(simulatedChartDataHashes)) {
      setSimulatedChartDataHashes({})
      setBenchmarkChartDataHashes({})
    }
    if (!forecast_simulator_scenario.run_model && isPresent(simulatedDecompData)) {
      setSimulatedDecompData({})
      setBenchmarkDecompData({})
    }
  }, [forecast_simulator_scenario.run_model])

  useEffect(() => {
    if (isBlank(forecastScenario.scenario_rows)) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => new Promise((resolve, reject) => {
          controller.signal.addEventListener('abort', () => reject());
          setFilteredSimulationScenarioRows(
            forecastScenario.fetchScopedRows({ cmusGroups })
          )
          resolve(true)
        }),
        () => new Promise((resolve, reject) => {
          controller.signal.addEventListener('abort', () => reject());
          setFilteredBenchmarkScenarioRows(
            forecastBenchmarkScenario.fetchScopedRows({ cmusGroups })
          )
          resolve(true)
        })
      ]
    })
  }, [cmuGroupsCacheKey])
  useEffect(() => {
    if (isBlank(forecastScenario.scenario_rows)) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => new Promise((resolve, reject) => {
          controller.signal.addEventListener('abort', () => reject());
          setFilteredSimulationScenarioRows(
            forecastScenario.fetchScopedRows({ cmusGroups })
          )
          resolve(true)
        })
      ]
    })
  }, [forecastScenario.scenario_rows])
  useEffect(() => {
    if (isBlank(forecastBenchmarkScenario?.scenario_rows)) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => new Promise((resolve, reject) => {
          controller.signal.addEventListener('abort', () => reject());
          setFilteredBenchmarkScenarioRows(
            forecastBenchmarkScenario.fetchScopedRows({ cmusGroups })
          )
          resolve(true)
        })
      ]
    })
  }, [(forecastBenchmarkScenario?.scenario_rows || [])])

  return <div className={`tab-content ${isDecompositionTab ? 'px-3 pb-3' : 'p-3'}`}>
    <TabContent {...{
      forecast_simulator_scenario,
      forecastScenario, forecastBenchmarkScenario, timeScale,
      isDecompositionTab, activeTab: localActiveTab,
      simulatedChartDataHashes, setSimulatedChartDataHashes,
      filteredSimulationScenarioRows, filteredBenchmarkScenarioRows,
      benchmarkChartDataHashes, setBenchmarkChartDataHashes,
      simulatedDecompData, setSimulatedDecompData,
      benchmarkDecompData, setBenchmarkDecompData,
      ...opts
    }} />
  </div>
}
const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });
export default connect(mapStateToProps, { updateScenarioData })(TabManager);
