import React from 'react';
import { connect } from 'react-redux';
import { Loader } from "../../common/Loader";

export const MESSAGES = {
  updating_scenario: 'Updating scenario',
  loading_rows: 'Loading rows',
  loading_benchmark_data: 'Loading benchmark data',
  syncing_scenario: 'Syncing scenario',
};

export const showOverlayWithMessage = (gridApi, updateScenarioData, message = null) => {
  updateScenarioData({ loading_component_message: message });
  gridApi?.setGridOption('loading', true);
};

export const hideOverlay = (gridApi) => {
  gridApi?.setGridOption('loading', null);
};

const CustomLoadingOverlay = ({ forecast_simulator_scenario }) => {
  return (
    <div className="ag-overlay-loading-center" role="presentation">
      <Loader marginTop={'mt-2'} />
      <div className="mt-2" aria-live="polite" aria-atomic="true">
        {forecast_simulator_scenario.loading_component_message || MESSAGES.updating_scenario}
      </div>
    </div>
  );
};
const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });
export default connect(mapStateToProps)(CustomLoadingOverlay);
