import { ForecastScenarioRow, groupRowsByCmus, TableRow } from "./ForecastScenarioRow";
import { getKeyByValue, isBlank, isPresent, qSortArray } from "../../helpers/common";
import * as moment from "moment";
import {
  aggregatedByCmu,
  aggregatedByTime,
  combinedAggregation, impactsAggregation
} from "./aggregastion";
import {
  SIMULATED_FACT_HEADER,
  TIME_SCALES,
  ROW_DRIVER_ID_KEY,
  FACTS_HEADER,
  SUB_FACT_HEADER,
  VALUE_SALES_FACT
} from "../../forecast_simulator_scenario/helpers/ag_grid_vars";
import { DEFAULT_SCOPES } from "../../forecast_simulator_scenario/helpers/scopes_helpers";
import { TIME_SCALES_KEYS } from "./ForecastTImeScale";
import { getScenarioFiltersJson } from "../../forecast_simulator_scenario/helpers/ag_grid_cookies";
export const DISPLAY_NAME_LIMIT = 50;

export const isFuturePeriod = (forecastScenario, periodId) => {
  return forecastScenario.allForecastedEditablePeriods.some(period => period.id === periodId);
};

const accumulateKeysByIndex = (rows) => {
  const result = {};
  const prefixMap = {};
  let currentIndex = 0;

  for (const row of rows) {
    const prefix = row.cmus[0];
    if (!(prefix in prefixMap)) {
      prefixMap[prefix] = currentIndex++;
    }

    const index = prefixMap[prefix];
    if (!result[index]) {
      result[index] = [];
    }
    if (!result[index].includes(row.cmusGroupKey)) {
      result[index].push(row.cmusGroupKey);
    }  }

  return result;
};
export class ForecastScenario {
  constructor(scenario, config, timeScale = null) {
    this.id = scenario.data.id;
    this.local_id = scenario.id;
    this.attributes = scenario.data.attributes;
    this.scenario_rows = isPresent(scenario.rows) ? scenario.rows : scenario.included;
    this.config = config;
    this.timeScale = timeScale ||
      (isPresent(scenario.view_options?.timeScale) ? scenario.view_options.timeScale : TIME_SCALES[0]);
    this.updateData = scenario.update_data;
    this.viewOptions = scenario.view_options;
    this.displayName = scenario.display_name;
    this.cmus = scenario.cmus;
    this.aggregatedFact = null;
  }

  get isAnnualTimeScale() {
    return this.timeScale === TIME_SCALES_KEYS.year;
  }

  setTimeScale(timeScale) {
    if (this.timeScale !== timeScale) {
      this._viewHeaders = null;
      this._allViewHeaders = null;
      this._rows = null;
      this._scenariosRows = null;
      this.timeScale = timeScale;
    }
  }

  get actualEditedCells() {
    return this.updateData?.edited_cells?.filter(cell => {
      if(cell.run_model_is_run) return true;
      if(isBlank(cell.default_value)) return false;

      const numericCellValue = typeof cell.value === 'number' ? cell.value : parseFloat(cell.value);
      const roundedCellValue = parseFloat(numericCellValue.toFixed(5));
      const numericDefaultValue = typeof cell.default_value === 'number' ? cell.default_value : parseFloat(cell.default_value);
      const roundedDefaultValue = parseFloat(numericDefaultValue.toFixed(5));
      return roundedCellValue !== roundedDefaultValue
    })
  }

  get actualRunModelCells() {
    return this.updateData?.run_model_cells || []
  }

  addedComparisonRows(rows) {
    const rowDriverIds = rows.map(row => row[ROW_DRIVER_ID_KEY]);
    return this.openedGroups?.flatMap((group) => group.added_rows).filter(row => rowDriverIds.some(id => row[ROW_DRIVER_ID_KEY].includes(id)));
  }

  get openedGroupsIds() {
    return this.openedGroups?.map(group => group.id);
  }

  get openedGroups() {
    return this.updateData?.opened_groups || [];
  }

  get simulationScopesData() {
    const filterScopes = this.viewOptions.scopes || {};
    const cmusGroups = Object.keys(filterScopes).map(columnId => {
      const { cmus, scope } = filterScopes[columnId];
      switch(scope) {
        case DEFAULT_SCOPES.custom:
          return cmus.map(cmuId => parseInt(cmuId));
        case DEFAULT_SCOPES.visible:
          const tableFilters = getScenarioFiltersJson(this)
          if (isBlank(tableFilters)) return [];

          const columnData = this.config.cmuColumns.find(column => column.id === parseInt(columnId))
          return tableFilters[columnData.displayName]?.values?.map(cmuValue => parseInt(getKeyByValue(columnData.values, cmuValue))) || [];
        default:
          return []
      }
    }).filter(isPresent)
    return {
      filterScopes, cmusGroups
    }
  }

  preparedRowsForTable(cmusGroups = null) {
    const filteredRows = cmusGroups ? this.findRowsByCmusGroups(cmusGroups) : this.rows;
    return Object.values(groupRowsByCmus(filteredRows)).map(rows => {
      const aggregatedRowData = {}
      this.config?.cmuHeaders.forEach(column => aggregatedRowData[column.displayName] = rows[0].fetchCmu(column))
      const driver = rows[0].selectedDriver
      aggregatedRowData[FACTS_HEADER] = this.driverWithMeasure(driver)
      aggregatedRowData[SUB_FACT_HEADER] = SIMULATED_FACT_HEADER;
      aggregatedRowData[ROW_DRIVER_ID_KEY] = rows[0].cmusDriverId;
      if (isPresent(driver.driverRules)) {
        this.allPeriodHeaders.forEach(period => {
          const rowsInPeriod = rows.filter(row => row.isInTimePeriod(period))
          const { value, format } = aggregatedByTime(rowsInPeriod, driver, period, this.config)
          if (format) {
            aggregatedRowData[period.name] = value;
          }
        })
      }
      return new TableRow(aggregatedRowData)
    }).map(aggregatedRow =>
      this.allViewHeaders.reduce((acc, header) => {
        acc[header] = aggregatedRow.fetchData(header);
        return acc;
      }, {})
    )
  }

  get groupFields() {
    if (isBlank(this._groupFields)) {
      this._groupFields = this.config?.cmuHeaders.map(column => column.displayName) || [];
    }
    return this._groupFields;
  }

  get firstForecastedPeriod() {
    if (isBlank(this._firstForecastedPeriod)) {
      this._firstForecastedPeriod = this.config.firstForecastedPeriod ||
        this.rows.find(row => !row.attributes.actualized)?.timePeriod;
    }
    return this._firstForecastedPeriod;
  }

  get allForecastedEditablePeriods() {
    if (isBlank(this._allForecastEditablePeriods)) {
      const timeScale = this.editableTimeScaleKey;
      this._allForecastEditablePeriods = this.config.getTimeScaleBy(timeScale).timePeriods
        .filter(period => period.startDate >= this.firstForecastedPeriod.startDate);
    }
    return this._allForecastEditablePeriods;
  }

  get editableTimeScaleKey() {
    return this.config.dataTimeScale.key;
  }

  get isEditableTimeScale() {
    return this.editableTimeScaleKey === this.timeScale;
  }

  allTimeScalePeriods(timeScale = this.timeScale) {
    return this.config?.getTimeScaleBy(timeScale)?.timePeriods;
  }

  periods({ from = null, to = null } = this.viewOptions, timeScale = this.timeScale) {
    const fromDate = isPresent(from) ? moment(from) : false
    const toDate = isPresent(to) ? moment(to) : false

    return this.allTimeScalePeriods(timeScale).filter(period => {
      if (fromDate && fromDate > period.startDate) return false;
      if (toDate && toDate < period.endDate) return false;

      return true;
    }) || [];
  }

  fetchScopedRows({
                    cmu = null, period = null, periods = [], cmus = [], cmusGroups = [], rows = false
                  }) {
    return (rows || this.scenariosRows).filter(row =>
      (isBlank(period) || row.isInTimePeriod(period)) &&
      (isBlank(periods) || periods.some(p => row.isInTimePeriod(p))) &&
      (isBlank(cmu) || row.cmus.includes(cmu)) &&
      (isBlank(cmus) || cmus.filter(isPresent).every(id => row.cmus.includes(id))) &&
      (isBlank(cmusGroups) || cmusGroups.every(cmusGroup => cmusGroup.some(id => row.cmus.includes(id))))
    );
  }

  aggregateBy({
                cmu = null, cmus = [], cmusGroups = [], rows = false,
                period, driver
              }) {
    const allCmuRows = this.fetchScopedRows({
      cmu, period, cmus, cmusGroups, rows
    });
    if (isBlank(allCmuRows)) return null;
    if (isPresent(driver?.driverRules)) {
      const aggregateFunction = period.timeScale.isInitData ? aggregatedByCmu : combinedAggregation
      const {format, value} = aggregateFunction(allCmuRows, driver, period, this.config)
      if (format) return value;
    }
    return 0.0;
  }

  aggregateAbsImpactBy({
                         cmu = null, cmus = [], cmusGroups = [], rows = false,
                         period, driver, metric
                       }) {
    const allCmuRows = this.fetchScopedRows({
      cmu, period, cmus, cmusGroups, rows
    });
    if (isPresent(driver?.decompRules[metric.id])) {
      const {format, value} = impactsAggregation(allCmuRows, driver, period, this.config, metric)
      if (format) {
        return value;
      }
    }
    return 0.0;
  }

  get allPeriodHeaders () {
    try {
      return this.periods({});
    } catch (e) {
      console.error("Error while parsing period headers", e);
      return []
    }
  }

  get periodHeaders() {
    try {
      return this.periods(this.viewOptions);
    } catch (e) {
      console.error("Error while parsing period headers", e);
      return []
    }
  }

  get periodHeadersNames() {
    return this.periodHeaders.map(period => period.name)
  }

  get allViewHeaders() {
    if (isBlank(this._allViewHeaders)) {
      this._allViewHeaders = [
        ROW_DRIVER_ID_KEY,
        ...this.config?.cmuHeaders.map(column => column.displayName),
        FACTS_HEADER,
        SUB_FACT_HEADER,
        ...this.allPeriodHeaders.map(period => period.name)
      ]
    }
    return this._allViewHeaders;
  }

  get viewHeaders() {
    if (isBlank(this._viewHeaders)) {
      this._viewHeaders = [
        ROW_DRIVER_ID_KEY,
        ...this.config?.cmuHeaders.map(column => column.displayName),
        FACTS_HEADER,
        SUB_FACT_HEADER,
        ...this.periodHeadersNames
      ]
    }
    return this._viewHeaders;
  }

  get rows() {
    if (isBlank(this._rows)) {
      this._rows = qSortArray(
        this.scenario_rows.flatMap(hash =>
          this.config.viewDriversBy(this.timeScale).map(column => new ForecastScenarioRow(hash, this, column))
        ), true, row => row.cmusSortKey
      )
    }
    return this._rows;
  }

  get cmusTable() {
    if (isBlank(this._cmusTable)) {
      this._cmusTable = accumulateKeysByIndex(this.rows);
    }
    return this._cmusTable;
  }

  get scenariosRows() {
    if (isBlank(this._scenariosRows)) {
      this._scenariosRows = qSortArray(
        this.scenario_rows.map(hash => new ForecastScenarioRow(hash, this)),
        true, row => row.cmusSortKey
      )
    }
    return this._scenariosRows;
  }

  driverWithMeasure(driver) {
    if(isBlank(driver)) return '';

    return [driver.displayName, driver.measure].filter(isPresent).join(', ')
  }

  get valueSalesDriverName() {
    if (isBlank(this._valueSalesDriver)) {
      const driver = this.findFactByDriverName(VALUE_SALES_FACT);
      this._valueSalesDriver = this.driverWithMeasure(driver);
    }
    return this._valueSalesDriver;
  }

  get outputDriversNames() {
    return this.config.outputColumns.map(column => column.displayName);
  }

  updateScenarioRows(rows) {
    this.scenario_rows = rows;
    this._rows = null;
    this._scenariosRows = null;
  }

  findFactByDriverName(driverName) {
    return this.config.allFactsColumns.find(column => column.displayName === driverName);
  }

  findRowsByCmusGroups(cmusGroups) {
    return this.rows.filter(row => cmusGroups.some(cmusGroup => cmusGroup.every(id => row.cmus.includes(id))))
  }

  findRowBy(cmus, periodId, driverId) {
    return this.rows.find(row => {
      return row.selectedDriver.id === Number(driverId) &&
        row.cmus.every(cmu => cmus.includes(cmu)) && row.timePeriod.id === Number(periodId)
    })
  }
}
