import React, { useMemo, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { setSimulationSettingsOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import { isMobile } from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import ChartsDecompositionScope from "./settings/ChartsDecompositionScope"
import TimeScaleSelector from "./settings/TimeScaleSelector";
import PeriodSelectors from "./settings/PeriodSelectors";
import {
  updateScenario,
  updateScenarioData,
  updateViewOptions
} from "../../store/forecast_simulator_scenario/actions";
import DefaultToButton from "./settings/DefaultToButton";
import { Loader } from "../../common/Loader";
import UpdateButton from "./settings/UpdateButton"
import { TIME_SCALES } from "../helpers/ag_grid_vars";
import {cmuScopes, DEFAULT_SCOPES} from "../helpers/scopes_helpers";
import { isBlank } from "../../helpers/common";
import { showOverlayWithMessage, MESSAGES, hideOverlay } from "../helpers/custom_loading_overlay";
import { TABS } from "../index";
import { isStoresLoading } from "../../helpers/callbacks_helpers";

const SimulationSettingsPanel = ({ activeTab,
                                   updateViewOptions, sidebar, openSideBar, forecast_simulator_scenario,
                                   config, forecastScenario, gridRef, updateScenario, updateScenarioData
                                 }) => {
  if(!forecast_simulator_scenario.scenario_loaded) return null;

  const prevTimeScale = forecastScenario.viewOptions.timeScale || TIME_SCALES[0];
  const [timeScale, setTimeScale] = useState(prevTimeScale);
  const defaultFrom = config.periodOptions(prevTimeScale)[0].start_date;
  const defaultTo = config.periodOptions(prevTimeScale).slice(-1)[0].end_date;
  const prevFrom = forecastScenario.viewOptions.from;
  const prevTo = forecastScenario.viewOptions.to;
  const prevDefaultScope = forecastScenario.viewOptions.defaultScope;
  const prevScopes = forecastScenario.viewOptions.scopes;
  const [from, setFrom] = useState(prevFrom);
  const [to, setTo] = useState(prevTo);
  const [disabled, setDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { defaultScope, scopes, onChangeDefaultScope, updateScope } = cmuScopes(config, forecastScenario, prevScopes);
  const activeTableTab = useMemo(() => activeTab === TABS.table, [activeTab]);

  useEffect(() => {
    if (isBlank(forecastScenario.viewOptions.simulated)) {
      onChangeDefaultScope(DEFAULT_SCOPES.visible)
    }
  }, [forecastScenario.viewOptions.simulated]);

  const dataChanged = () => {
    return timeScaleChanged() ||
      prevFrom !== from ||
      prevTo !== to ||
      defaultScope !== prevDefaultScope ||
      scopes !== prevScopes;
  }

  const tableDataChanged = () => {
    return timeScaleChanged() || prevFrom !== from || prevTo !== to;
  }

  const timeScaleChanged = () => {
    return prevTimeScale !== timeScale;
  };

  const onSubmit = () => {
    setShowLoader(false);
    setDisabled(true);
    openSideBar(false);
    if(dataChanged()) {
      if(tableDataChanged())
        showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
      updateViewOptions({ timeScale, from, to, defaultScope, scopes }, () => {
        hideOverlay(gridRef?.current?.api);
        setDisabled(false);
      });
    }
  };

  const presetTimeScales = () => {
    setFrom(prevFrom || defaultFrom)
    setTo(prevTo || defaultTo)
    setTimeScale(prevTimeScale)
  }

  const changeTimeScale = (value) => {
    setTimeScale(value)
    setFrom(defaultFrom);
    setTo(defaultTo);
  }

  return <SlidingPanel
    type="left"
    isOpen={sidebar.simulationSettingsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper simulator-settings-panel-wrapper tree-page ms-0 ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content d-flex flex-column h-100`}
    size={52}
    onOpening={presetTimeScales}
  >
    <PanelHeader headerName={'Simulation settings'} openSideBar={openSideBar} preview={true} />
    <TimeScaleSelector {...{ timeScale, changeTimeScale, options: config.timeScalesOptions }} />
    <PeriodSelectors  {...{ to, setTo, from, setFrom, options: config.periodOptions(timeScale) }} />
    <ChartsDecompositionScope {...{ forecastConfig: config, updateScope, scopes, keyBase: 'simulation-settings-panel', scenarioCmus: forecast_simulator_scenario.cmus }} />
    <DefaultToButton {...{
      defaultScope, setDefaultScope:
      onChangeDefaultScope,
      disabled: (isStoresLoading(forecast_simulator_scenario) || isBlank(forecast_simulator_scenario.cmus))
    }} />
    {disabled || showLoader ? <Loader /> : null}
    <UpdateButton {...{onSubmit, disabled: disabled || showLoader, setShowLoader}}/>
  </SlidingPanel>
}

const mapStateToProps = ({ sidebar, forecast_simulator_scenario }) => ({ sidebar, forecast_simulator_scenario });
const mapDispatchToProps = (dispatch) => ({
  updateViewOptions: (params, callback) => dispatch(updateViewOptions(params, callback)),
  openSideBar: (flag) => dispatch(setSimulationSettingsOpen(flag)),
  updateScenario: (scenario_id, updateData, callback) =>
    dispatch(updateScenario(scenario_id, updateData, callback, false)),
  updateScenarioData: (data) => dispatch(updateScenarioData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(SimulationSettingsPanel);
