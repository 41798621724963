import React from "react";
import WaterfallChart, { COLORS } from "../charts/WaterfallChart";
import { CHART_VALUES_TYPES } from "./ChartValuesType";
import TotalsBlock from "./TotalsBlock";
import { arrayLastElement, isBlank, isPresent, qSortArray } from "../../../helpers/common";

const fetchImpactValue = ({
                            scenario,
                            chartValuesType,
                            impact, metric,
                            chartPeriod,
                            startValue,
                            cmus = [], cmusGroups = [], rows = false
                          }) => {
  try {
    const aagregatedImpactsValue = scenario.aggregateAbsImpactBy({
      driver: impact, period: chartPeriod, cmus, cmusGroups, rows, metric
    })
    return chartValuesType === CHART_VALUES_TYPES.percent ? aagregatedImpactsValue / startValue : aagregatedImpactsValue
  } catch(e) {
    console.log('fetchImpactValue', e.message)
    return 0;
  }
}

const prepareChartData = ({
                            scenario, config,
                            chartValuesType,
                            metric,
                            yearAgoPeriod, chartPeriod,
                            cmus = [], cmusGroups = [], rows = false,
                            startValue, endValue
                          }) => {
  if (isBlank(metric) || isBlank(scenario)) return [];

  const impacts = qSortArray(
    config.driversColumns.filter(dr => isPresent(dr.decompRules[metric.id]?.visible)),
    true, impact => impact.decompId(metric)
  )

  const startChartValue = chartValuesType === CHART_VALUES_TYPES.value ? metric.prepareValueForChart(startValue) : 100
  const endChartValue = chartValuesType === CHART_VALUES_TYPES.value ? metric.prepareValueForChart(endValue) : (endValue / startValue) * 100

  const changeValue = endChartValue - startChartValue
  let impactsSum = 0
  const rowsData = impacts.map(impact => {
    if (impact.isAllOtherImpacts(metric)) {
      return ({
        name: impact.decompName(metric) || impact.displayName,
        y: changeValue - impactsSum
      })
    }
    const rawImpactValue = fetchImpactValue({
      scenario, config,
      chartValuesType,
      impact, metric,
      chartPeriod,
      cmus, cmusGroups, rows,
      startValue
    })
    const impactValue = chartValuesType === CHART_VALUES_TYPES.percent ? rawImpactValue * 100 : metric.prepareValueForChart(rawImpactValue);

    impactsSum += impactValue;
    return ({
      name: impact.decompName(metric) || impact.displayName,
      y: impactValue
    })
  })

  const caps = [startChartValue]
  rowsData.forEach((row, index) => {
    if (index === 0) {
      caps.push(startChartValue + row.y)
    } else {
      caps.push(caps[index-1] + row.y)
    }
  })
  const sortedCaps = qSortArray(caps, true, el => el)
  const minCap = endChartValue < sortedCaps[0] ? endChartValue : sortedCaps[0]
  const maxCap = endChartValue > arrayLastElement(sortedCaps) ? endChartValue : arrayLastElement(sortedCaps)

  return {
    chartsData: [
      {
        name: yearAgoPeriod.name,
        y: startChartValue
      },
      ...rowsData,
      {
        name: chartPeriod.name,
        color: COLORS[2],
        isSum: true
      }
    ],
    changeValue, minCap, maxCap
  }
}

const fetchStartValue = ({
                           scenario, config,
                           calcPeriod, fromPeriod, toPeriod,
                           metric, cmus, cmusGroups, rows,
                           setFrom = () => {},
                           setWarning = () => {},
                           fetchIndex = 0
                         }) => {
  const result = scenario?.aggregateBy({ period: calcPeriod, driver: metric, cmus, cmusGroups, rows })

  if (fromPeriod.id === toPeriod.id) return result;
  // if (result === 0 || result == null) {
  //   const nextPeriod = fromPeriod.timeScale.timePeriods.find(tp => tp.startDate > fromPeriod.startDate)
  //   const nextToPeriod = toPeriod.timeScale.timePeriods.find(tp => tp.startDate > toPeriod.startDate)
  //   return fetchStartValue({
  //     scenario, config,
  //     calcPeriod: generateCustomPeriod({
  //       fromPeriod: genYearAgoPeriod({ period: nextPeriod }),
  //       toPeriod: genYearAgoPeriod({ period: nextToPeriod }),
  //       timeScale: fromPeriod.timeScale
  //     }),
  //     fromPeriod: nextPeriod, toPeriod,
  //     metric, cmus, cmusGroups, rows,
  //     setFrom, fetchIndex: fetchIndex + 1,
  //     setWarning
  //   })
  //   // return null;
  // }
  if (fetchIndex > 0) {
    setTimeout(() => setFrom(fromPeriod.start_date), 50)
    setWarning(`Calculation starts from period ${fromPeriod.optionLabel}`)
  } else {
    setWarning(null)
  }

  return result;
}

export const fetchDecompositionData = ({
                                         scenario, config,
                                         fromPeriod, toPeriod,
                                         yearAgoPeriod, chartPeriod,
                                         metric, chartValuesType,
                                         cmus = [],
                                         cmusGroups = [],
                                         rows = false,
                                         setFrom = () => {},
                                         setWarning = () => {}
                                       }) => {
  if (isBlank(scenario)) return {};

  const startValue = fetchStartValue({
    scenario, config,
    calcPeriod: yearAgoPeriod,
    fromPeriod, toPeriod,
    metric, cmus, cmusGroups, rows,
    setFrom, setWarning
  })
  if (startValue === null) return { startValue: null, endValue: null, chartsData: null };

  const endValue = scenario?.aggregateBy({
    period: toPeriod.timeScale.isYear ? toPeriod : chartPeriod,
    driver: metric,
    cmus, cmusGroups, rows
  })

  const chartsData = prepareChartData({
    scenario, metric,
    yearAgoPeriod, chartPeriod, rows,
    config,
    chartValuesType,
    startValue, endValue,
    cmus, cmusGroups
  })

  return {
    startValue, endValue, ...chartsData
  }
}

const DecompositionChartBlock = ({
                                   scenario = null, fromPeriod = null, toPeriod = null,
                                   metric, chartValuesType, chartsData,
                                   startValue = null, endValue = null, changeValue = null,
                                   minCap = null, maxCap= null,
                                   showTotals = true, title = false,
                                   showPlaceholder = false
                                 }) => {
  const measure = chartValuesType === CHART_VALUES_TYPES.percent ? '%' : metric.measureForChart;
  const decimal = chartValuesType === CHART_VALUES_TYPES.percent ? 2 : metric.decimal;

  const capsDiff = minCap == null ? null : (maxCap - minCap) > minCap ? minCap : maxCap - minCap
  const calculatedMinCap = minCap == null ? null : minCap - capsDiff*0.1
  const calculatedMaxCap = maxCap == null ? null : maxCap + capsDiff*0.05

  return <div className="mt-2 charts-container d-lg-flex">
    <div className="chart-col pe-lg-2">
      {
        startValue === 0 || startValue == null ?
          showPlaceholder && <p className={`text-center text-bold`}><strong>Please change From date period</strong></p> :
          <WaterfallChart
            title={title || `${scenario?.displayName} ${fromPeriod?.name}-${toPeriod?.name}`}
            {...{ data: chartsData, decimal, measure, minCap: calculatedMinCap, maxCap: calculatedMaxCap }}
          />
      }
    </div>
    <div className="totals-col">
      {
        startValue == null ? null :
        (startValue === 0) && !showPlaceholder ? null :
        showTotals && <TotalsBlock {...{ driver: metric, startValue, endValue, changeValue, changeMeasure: measure, changeDecimal: decimal }} />
      }
    </div>
  </div>
}
export default DecompositionChartBlock;
