import React, { useState, useRef } from 'react';
import { CheckForm } from "../../../tree_wizard/helpers/DecisionFlowRow";

const AllowCollaboratorsEditTreeInput = ({ decision, onChangeSettings }) => {
  const [submit, setSubmit] = useState(true);
  const { allow_collaborators_edit_tree, read_only } = decision;
  const checkboxRef = useRef(null);

  return(
    <CheckForm
      label={'Allow collaborators to edit the decision tree'}
      disabled={!submit || read_only}
      type={'checkbox'}
      className='mb-0 mt-2'
      checked={allow_collaborators_edit_tree === true}
      onClick={() => onChangeSettings({ allow_collaborators_edit_tree: !allow_collaborators_edit_tree }, checkboxRef, setSubmit)}
      ref={checkboxRef}
    />
  )
};

export default AllowCollaboratorsEditTreeInput;
