import React, {useEffect, useState} from "react";
import Wrapper from "../Wrapper";
import Decision, {LEGACY_CHOICE_ENTRY_TYPE_DATA} from "../../../models/decision";
import {isPresent} from "../../../helpers/common";
import PollInfo from "../poll/PollInfo";
import PollMoreActionsSection from "../../side_panel/sections/PollMoreActionsSection";
import ExpandedPollContent from "../poll/ExpandedPollContent";
import { updateTreeData } from "../../../store/tree/common_actions";
import {connect} from "react-redux";
import {ArrowUpIcon} from "../../../icons/custom_icons";

const ChoiceType = ({decision}) => {
  const decisionObj = new Decision(decision)
  const recommendation = decision.recommendation
  const flow = decisionObj.isRecommendationFlow ? recommendation.choice_entry_widget_type : decision.choice_entry_widget_type

  return <span className="text-muted me-1 h5 fw-normal">({LEGACY_CHOICE_ENTRY_TYPE_DATA[flow]})</span>
}

export const ContractPollHeader = ({expanded, decision, usersChoicesVotes, setExpanded}) =>
  <div className="d-flex" onClick={() => setExpanded(!expanded)}>
    <div className="d-flex flex-column my-auto lh-sm w-100 me-1 pointer" style={{minHeight: 36}}>
      <div className="d-flex">
        <span className={`${expanded ? 'h3' : 'fw-bolder'}`}>
          Decision poll
        </span>
        <span className="text-primary fw-bolder pointer ms-1">
          <i className="fas fa-chevron-down down fa-sm"
             style={{transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)'}}/>
        </span>
      </div>
      <PollInfo {...{decision, usersChoicesVotes}} />
    </div>
    <div>
      <div className={`btn btn-secondary btn-sm btn-sm-round`}>
        <i className="fa-solid fa-check-to-slot" style={{fontSize: 18}}></i>
      </div>
    </div>
  </div>

export const ExpandedPollHeader = ({expanded, decision, setExpanded}) =>
  <div className='d-flex'>
    <div className="d-inline-flex w-100 pointer my-auto" onClick={() => setExpanded(!expanded)}>
      <div className={`me-1 ${expanded ? 'h3' : ''}`}>Decision poll</div>
      <ChoiceType {...{decision}} />
      <span className={`text-primary fw-bolder pointer`}>
        <i className={`fas fa-chevron-down down fa-sm`} style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
      </span>
    </div>
    <div className="float-end">
      <PollMoreActionsSection className={'mt-2-negative me-2-negative'} />
    </div>
  </div>

const RecordedDecisionPollCard = ({ decision, usersChoicesVotes, object, hideEdit = false, tree, updateTreeData,
                                  showArrow = false, showBefore = false}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if(isPresent(tree.expand_poll_results)) {
      setTimeout(() => {
        setExpanded(true);
        const selectedEl = isPresent(tree.feedback_message_id) ?
          document.getElementById(`message-row-${tree.feedback_message_id}`) :
          document.getElementById(`poll-card`)
        if (selectedEl) {
          setTimeout(() => selectedEl.scrollIntoView({behavior:'smooth', inline: 'start'}), 100)
        }
        updateTreeData({expand_poll_results: '', feedback_message_id: '' })
      }, 100)

    }
  }, [tree.expand_poll_results])

  return <Wrapper isPoll={true} decisionObject={new Decision(decision)} {...{showBefore, expanded}}>
    {
      expanded ?
        <>
          <ExpandedPollHeader {...{decision, expanded, usersChoicesVotes, setExpanded}} />
          <ExpandedPollContent {...{decision, expanded, usersChoicesVotes, object, hideEdit}} />
        </> :
        <ContractPollHeader {...{expanded, decision, usersChoicesVotes, setExpanded}} />
    }
    <div className={`arrow-up-icon-block ${showArrow ? 'd-block' : 'd-none'} ${expanded ? 'ml-expanded' : ''}`}>
      <ArrowUpIcon/>
    </div>
  </Wrapper>
}

export const mapStateToProps = ({tree}) => ({tree});

export default connect(mapStateToProps, {updateTreeData})(RecordedDecisionPollCard);