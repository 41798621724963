import React from 'react';

const TablePaginationPanel = ({ page, onChangePage, totalPages }) => {

  const handleLastClick = () => {
    if (page + 1 >= totalPages) return;
    onChangePage(totalPages - 1);
  };

  const handleFirstClick = () => {
    if (page === 0) return;

    onChangePage(0);
  };

  const handlePrevClick = () => {
    if (page - 1 < 0) return;

    onChangePage(page - 1);
  };

  const handleNextClick = () => {
    if(page + 1 >= totalPages) return;

    onChangePage(page + 1);
  };

  return (
    <div className="ag-paging-panel ag-unselectable ag-focus-managed" id="ag-32">
      <div className="ag-tab-guard ag-tab-guard-top" role="presentation" tabIndex="0"/>
      <span className="ag-paging-page-size" data-ref="pageSizeComp"/>
      <span className="ag-paging-page-summary-panel" role="presentation">
        <div onClick={handleFirstClick}
             data-ref="btFirst"
             className={`ag-button ag-paging-button ${page > 0 ? '' : 'ag-disabled'}`}
             role="button"
             aria-label="First Page"
             tabIndex="0"
             aria-disabled="true">
          <span className="ag-icon ag-icon-first" unselectable="on" role="presentation"/>
        </div>
        <div data-ref="btPrevious"
             className={`ag-button ag-paging-button ${page > 0 ? '' : 'ag-disabled'}`}
             role="button"
             onClick={handlePrevClick}
             aria-label="Previous Page" tabIndex="0" aria-disabled="true"><span
          className="ag-icon ag-icon-previous" unselectable="on" role="presentation"/></div>
        <span className="ag-paging-description" role="status">
          <span id="ag-32-start-page">Page</span>&nbsp;
          <span id="ag-32-start-page-number" data-ref="lbCurrent"
                className="ag-paging-number">{page + 1}</span>&nbsp;
          <span id="ag-32-of-page">of</span>&nbsp;
          <span id="ag-32-of-page-number" data-ref="lbTotal"
                className="ag-paging-number">{totalPages}</span>
        </span>
        <div data-ref="btNext"
             className={`ag-button ag-paging-button ${(page + 1) < totalPages ? '' : 'ag-disabled'}`}
             role="button"
             onClick={handleNextClick}
             aria-label="Next Page" tabIndex="0"
             aria-disabled="true">
          <span className="ag-icon ag-icon-next" unselectable="on" role="presentation"/>
        </div>
        <div data-ref="btLast"
             className={`ag-button ag-paging-button ${page + 1 < totalPages ? '' : 'ag-disabled'}`}
             role="button"
             onClick={handleLastClick}
             aria-label="Last Page" tabIndex="0" aria-disabled="true">
          <span className="ag-icon ag-icon-last" unselectable="on" role="presentation"/>
        </div>
      </span>
      <div className="ag-tab-guard ag-tab-guard-bottom" role="presentation" tabIndex="0"></div>
    </div>
  );
};

export default TablePaginationPanel
;