import { isBlank } from "../../helpers/common";

export const SEMAPHORES = {
  g_null: 'g_null',
  grey: 'grey',
  red: 'red',
  yellow: 'yellow',
  green: 'green',
  g_red: 'g_red',
  g_yellow: 'g_yellow',
  g_green: 'g_green',
  placeholder: 'placeholder'
}

export const BLANK_SEMAPHORES = ['g_null', 'grey']

export const INSIGHT_TYPE = window.TreeLayoutConstants.dsight.insight_types

export const INSIGHT_MESSAGE_TYPE = {
  decision: 'decision',
  answer: 'conclusion',
  message: 'insight',
  question: 'question'
};

export const skipSemaphore = (semaphore) => {
  if (isBlank(semaphore)) return true;
  return BLANK_SEMAPHORES.includes(semaphore);
}

export default class Insight {
  constructor(data) {
    this.data = data
  }
}
