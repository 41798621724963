import { isBlank } from "../../helpers/common";
import { groupRowsByCmus } from "./ForecastScenarioRow";

const IGNORE_AGGREGATION = ['N/A', 'none', 'do not aggregate at all']
export const AGGREGATION_TYPES = {
  by_time: 'aggregate_within',
  by_cmu: 'aggregate_sub_totals'
};

const periodValues = (rows, driver) => rows.map(row => ({ value: driver.fetchValue(row) }))
const periodImpacts = (rows, driver, metric) => rows.map(row => ({
  value: driver.fetchImpact(row, metric)
}))
const valueForCallback = (rows, config) => (driverName) => {
  const relatedDriver = config.allFactsColumns.find(col => col.name === driverName)
  return periodValues(rows, relatedDriver).map(h => h.value)
}
const aggregatedValueForCallback = (rows, period, config, func) => (driverName) => {
  const relatedDriver = config.allFactsColumns.find(col => col.name === driverName)
  return func(rows, relatedDriver, period, config).value
}

export const ignoreAggregation = (aggregationType) => isBlank(aggregationType) || IGNORE_AGGREGATION.includes(aggregationType)

const aggregatedValue = (hashes, aggregationType, { aggregatedValueFor, valuesFor }) => {
  const values = hashes.map(h => h.value);
  if(values.length === 1) return { value: values[0], format: true }

  if (ignoreAggregation(aggregationType)) { return { value: 'do not aggregate', format: false } }
  switch(aggregationType) {
    case 'sum':
      return { value: values.reduce((acc, value) => acc + parseFloat(value), 0), format: true }
    case 'Average':
    case 'avg':
      return { value: values.reduce((acc, value) => acc + parseFloat(value), 0) / values.length, format: true }
    case 'max':
      return { value: Math.max(...values), format: true }
    case 'min':
      return { value: Math.min(...values), format: true }
    case 'Ratio(Volume Sales,Unit Sales)':
      const aggregatedUnitesForVolumes = aggregatedValueFor('Unit Sales', hashes)
      if (aggregatedUnitesForVolumes === 0) return { value: 0, format: true };

      const aggregatedVolumes = aggregatedValueFor('Volume Sales', hashes)
      return {
        value: aggregatedVolumes / aggregatedUnitesForVolumes,
        format: true,
        aggregatedValueFor: {
          'Unit Sales': aggregatedUnitesForVolumes,
          'Volume Sales': aggregatedVolumes
        }
      }
    case 'Value Sales / Unit Sales':
    case 'Ratio(Value Sales,Unit Sales)':
      const aggregatedUnites = aggregatedValueFor('Unit Sales', hashes)
      if (aggregatedUnites === 0) return { value: 0, format: true };

      const aggregatedValues = aggregatedValueFor('Value Sales', hashes)
      return {
        value: aggregatedValues / aggregatedUnites,
        format: true,
        aggregatedValueFor: {
          'Unit Sales': aggregatedUnites,
          'Value Sales': aggregatedValues
        }
      }
    case 'Sum abs impact and weighted on Volume Sales':
    case 'Sum abs impact and weighted on Value Sales':
    case 'Sum weighted by units':
    case 'Sum weighted by values':
      const sumDriverOnValues = values.reduce((acc, value) => acc + parseFloat(value), 0)
      return {
        value: sumDriverOnValues,
        format: true,
        valuesFor: {}
      }
    case 'Weighted on(Distribution)':
      const dst_values = valuesFor('Total Points of Distribution', hashes)
      const weighSumDriverOnDist = values.reduce((acc, value, index) => acc + (parseFloat(value) * parseFloat(dst_values[index])), 0)
      const sumDist = dst_values.reduce((acc, value) => acc + parseFloat(value), 0)
      return {
        value: (weighSumDriverOnDist / sumDist),
        format: true,
        valuesFor: {
          'Total Points of Distribution': sumDist
        }
      }
    case 'Weighted on(Value)':
    case 'Weighted on Value':
      const value_values = valuesFor('Value Sales', hashes)
      const weighSumDriverOnValues = values.reduce((acc, value, index) => acc + (parseFloat(value) * parseFloat(value_values[index])), 0)
      const sumValues = value_values.reduce((acc, value) => acc + parseFloat(value), 0)
      return {
        value: (weighSumDriverOnValues / sumValues),
        format: true,
        valuesFor: {
          'Value Sales': sumValues
        }
      }
    case 'Weighted on(Units)':
    case 'Weighted on Units':
      const units_values = valuesFor('Unit Sales', hashes)
      const weighSumDriverOnUnits = values.reduce((acc, value, index) => acc + (parseFloat(value) * parseFloat(units_values[index])), 0)
      const sumUnits = units_values.reduce((acc, value) => acc + parseFloat(value), 0)
      return {
        value: (weighSumDriverOnUnits / sumUnits),
        format: true,
        valuesFor: {
          'Unit Sales': sumUnits
        }
      }
    default:
      return { value: `Unsupported for now : ${aggregationType}`, format: false }
  }
}

export const aggregatedByTime = (rows, driver, period, config)  => {
  const driverRules = driver.driverRules;
  const timeScaleConfig = config.timeScales.find(ts => ts.key === period.timeScaleKey)
  const aggregationType = timeScaleConfig.isInitData ? 'sum' : driverRules[AGGREGATION_TYPES.by_time][period.timeScaleKey];
  const hashes = periodValues(rows, driver);
  return aggregatedValue(
    hashes, aggregationType, {
      aggregatedValueFor: aggregatedValueForCallback(rows, period, config, aggregatedByTime),
      valuesFor: valueForCallback(rows, config)
    }
  )
}
export const aggregatedByCmu = (rows, driver, period, config, values = false) => {
  const driverRules = driver.driverRules;
  const timeScaleConfig = config.timeScales.find(ts => ts.key === period.timeScaleKey)
  const aggregationType = timeScaleConfig.isInitData ? 'sum' : driverRules[AGGREGATION_TYPES.by_cmu][period.timeScaleKey];
  const hashes = values || periodValues(rows, driver);
  return aggregatedValue(
    hashes, aggregationType, {
      aggregatedValueFor: aggregatedValueForCallback(rows, period, config, aggregatedByCmu),
      valuesFor: valueForCallback(rows, config)
    }
  )
}

export const combinedAggregation = (rows, driver, period, config) => {
  const driverRules = driver.driverRules;
  const timeScaleConfig = config.timeScales.find(ts => ts.key === period.timeScaleKey)
  const aggregationTypeByTime = timeScaleConfig.isInitData ? 'sum' : driverRules[AGGREGATION_TYPES.by_time][period.timeScaleKey];
  const aggregationTypeByCmu = timeScaleConfig.isInitData ? 'sum' : driverRules[AGGREGATION_TYPES.by_cmu][period.timeScaleKey];
  if (aggregationTypeByTime === aggregationTypeByCmu) {
    const hashes = periodValues(rows, driver);
    return aggregatedValue(
      hashes, aggregationTypeByTime, {
        aggregatedValueFor: aggregatedValueForCallback(rows, period, config, combinedAggregation),
        valuesFor: valueForCallback(rows, config)
      }
    )
  } else {
    const groupedByTime = Object.values(groupRowsByCmus(rows)).map(rows => {
      return {
        ...aggregatedByTime(rows, driver, period, config),
        rows
      }
    });
    return aggregatedValue(
      groupedByTime, aggregationTypeByCmu, {
        aggregatedValueFor: (driverName, hashes) => {
          const subAggregationType = config.allFactsColumns.find(col => col.name === driverName).driverRules[AGGREGATION_TYPES.by_cmu][period.timeScaleKey]
          const values = hashes.map(h => ({ value: h.aggregatedValueFor[driverName]  }) )
          return aggregatedValue(values, subAggregationType, { aggregatedValueFor: () => 0.0, valuesFor: () => 0.0 }).value
        },
        valuesFor: (driverName, hashes) => hashes.map(h => h[driverName])
      }
    )
  }
}

export const impactsAggregation = (rows, driver, period, config, metric) => {
  const rules = driver.decompRules[metric.id];
  const aggregationType = fetchImpactAggregationRule(rules, period.timeScaleKey);
  const hashes = periodImpacts(rows, driver, metric);

  return aggregatedValue(
    hashes, aggregationType, {
      aggregatedValueFor: () => 0.0,
      valuesFor: (_driverName) => periodValues(rows, metric).map(h => h.value)
    }
  );
}

const fetchImpactAggregationRule = (rules, timeScale) => {
  return rules.aggregate[timeScale] || rules.aggregate.default || Object.values(rules.aggregate)[0];
}

