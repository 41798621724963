import { useGridFilter } from 'ag-grid-react';
import React, {useState, useCallback, useRef, useEffect} from 'react';
import {getScenarioFiltersJson} from "./ag_grid_cookies";
import { FACT_COMPARISON_HEADERS_NAMES, VALUE_SALES_FACT } from "./ag_grid_vars";

const CustomFilter = ({ colDef, model, onModelChange, getValue, context }) => {
  const refInput = useRef(null);
  const allValues = colDef.filterParams.values || [];
  const filterModel = getScenarioFiltersJson(context.forecastScenario);
  const [checkedValues, setCheckedValues] = useState(filterModel[colDef.colId]?.values || allValues);
  const [filteredValues, setFilteredValues] = useState(allValues);
  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };


  const doesFilterPass = useCallback(
    (params) => {
      const { node } = params;
      const value = getValue(node);
      const filterValues = model.values || [];
      // Check '' to catch comparison rows
      return filterValues.includes(value) || value === '';
    },
    [model]
  );

  const afterGuiAttached = useCallback((params) => {
    if (!params || !params.suppressFocus) {
      refInput.current.focus();
    }
  }, []);

  useEffect(() => {
    setFilteredValues(
      allValues.filter(value => value.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [searchText, allValues]);

  // register filter handlers with the grid
  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });

  const checkBoxChangeCallback = (newValues) => {
    const values = newValues.length === allValues.length ? null : { values: newValues };
    onModelChange(values);
    setCheckedValues(values === null ? allValues : newValues);
  };

  const onCheckedChange = (filterValue) => {
    let newValues = [];
    if(filterValue.includes(VALUE_SALES_FACT)) {
      newValues = checkedValues.filter((value) => ![filterValue, ...FACT_COMPARISON_HEADERS_NAMES].includes(value));
    } else {
      newValues = checkedValues.filter((value) => value !== filterValue);
    }
    checkBoxChangeCallback(newValues);
  };

  const onUncheckedChange = (filterValue) => {
    let newValues = [];
    if(filterValue.includes(VALUE_SALES_FACT)) {
      newValues = Array.from(new Set([...checkedValues, filterValue, ...FACT_COMPARISON_HEADERS_NAMES]));
    } else {
      newValues = [...checkedValues, filterValue];
    }
    checkBoxChangeCallback(newValues);
  };

  const handleCheckboxChange = (filterValue) => {
    if (checkedValues.includes(filterValue)) {
      onCheckedChange(filterValue);
    } else {
      onUncheckedChange(filterValue);
    }
  };

  const handleSelectAllChange = () => {
    if (checkedValues.length === filteredValues.length) {
      checkBoxChangeCallback([]);
    } else {
      checkBoxChangeCallback(allValues);
    }
  };

  return (
    <form className="ag-filter-wrapper ag-focus-managed">
      <div className="ag-filter-body-wrapper ag-set-filter-body-wrapper" data-ref="eFilterBody">
        <div className="ag-set-filter">
          <div data-ref="eFilterLoading" className="ag-filter-loading ag-hidden">Loading</div>
          <div role="presentation"
               className="ag-mini-filter ag-labeled ag-label-align-left ag-text-field ag-input-field"
               data-ref="eMiniFilter">
            <div data-ref="eLabel" className="ag-input-field-label ag-label ag-hidden ag-text-field-label"
                 aria-hidden="true" role="presentation" id="ag-92-label"></div>
            <div data-ref="eWrapper" className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
                 role="presentation">
              <input
                ref={refInput}
                data-ref="eInput"
                className="ag-input-field-input ag-text-field-input"
                type="text"
                id="ag-92-input"
                tabIndex="0"
                aria-label="Search filter values"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div data-ref="eFilterNoMatches" className={`ag-filter-no-matches ${filteredValues.length === 0 ? '' : 'ag-hidden'}`}>No matches.</div>
          <div data-ref="eSetFilterList" className={`ag-set-filter-list ${filteredValues.length === 0 ? 'ag-hidden' : ''}`} role="presentation"
               style={{maxHeight: '141px'}}>
            <div className="ag-virtual-list-viewport ag-filter-virtual-list-viewport ag-focus-managed"
                 role="presentation">
              <div className="ag-tab-guard ag-tab-guard-top" role="presentation" tabIndex="0"></div>
              <div className="ag-virtual-list-container ag-filter-virtual-list-container" data-ref="eContainer"
                   role="listbox" aria-label="Filter List"
                   style={{
                     height: '-webkit-fill-available',
                     overflowY: 'inherit'
                   }}>
                <div className="ag-virtual-list-item ag-filter-virtual-list-item" role="option"
                     aria-setsize={filteredValues.length + 1} aria-posinset={1} tabIndex="-1"
                     aria-checked={checkedValues.length === filteredValues.length} style={{height: '24px', top: '0px'}}>
                  <div className="ag-set-filter-item">
                    <div role="presentation" data-ref="eCheckbox"
                         className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                      <div data-ref="eLabel"
                           className="ag-input-field-label ag-label ag-checkbox-label ag-label-ellipsis"
                           aria-hidden="false" id="ag-select-all-label">(Select All)
                      </div>
                      <div data-ref="eWrapper"
                           className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${checkedValues.length === filteredValues.length ? 'ag-checked' : ''}`}
                           role="presentation">
                        <input
                          data-ref="eInput"
                          className="ag-input-field-input ag-checkbox-input"
                          type="checkbox"
                          id="ag-select-all-input"
                          tabIndex="-1"
                          aria-labelledby="ag-select-all-label"
                          checked={checkedValues.length === filteredValues.length}
                          onChange={handleSelectAllChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {(filteredValues).map((filterValue, index) => (
                  <div key={index} hidden={FACT_COMPARISON_HEADERS_NAMES.includes(filterValue)} className="ag-virtual-list-item ag-filter-virtual-list-item" role="option"
                       aria-setsize={filteredValues.length + 1} aria-posinset={index + 2} tabIndex="-1"
                       aria-checked={checkedValues.includes(filterValue)}
                       style={{height: '24px', top: `${(index + 1) * 24}px`}}>
                    <div className="ag-set-filter-item">
                      <div role="presentation" data-ref="eCheckbox"
                           className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                        <div data-ref="eLabel"
                             className="ag-input-field-label ag-label ag-checkbox-label ag-label-ellipsis"
                             aria-hidden="false" id={`ag-${index}-label`}>{filterValue}</div>
                        <div data-ref="eWrapper"
                             className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${checkedValues.includes(filterValue) ? 'ag-checked' : ''}`}
                             role="presentation">
                          <input
                            data-ref="eInput"
                            className="ag-input-field-input ag-checkbox-input"
                            type="checkbox"
                            id={`ag-${index}-input`}
                            tabIndex="-1"
                            aria-labelledby={`ag-${index}-label`}
                            checked={checkedValues.includes(filterValue)}
                            onChange={() => handleCheckboxChange(filterValue)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="ag-tab-guard ag-tab-guard-bottom" role="presentation" tabIndex="0"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CustomFilter;